import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';

class MySelect extends Component {

    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(this.props.name, value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur(this.props.name, true);
    };

    render() {
        return (
            <div>
                <div className="row" style={{marginLeft: "2px"}}>
                    <div>
                        <label htmlFor="color">{this.props.title}</label>
                    </div>
                </div>
                <CreatableSelect
                    isDisabled={this.props.disabled}
                    maxMenuHeight={200}
                    id="color"
                    options={this.props.newItems}
                    isMulti={true}
                    label=""
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                />
                {!!this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                )}
            </div>
        );
    }
}

export default MySelect;
