import React from 'react';
import { FormControl, MenuItem, Divider, Button, DialogContent,DialogTitle }
    from '../materialUi.js';
import TextField from "../FormikTextField";
import * as Yup from 'yup';
import { Field, withFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import useStyles from "../styleGrid"

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        internal_number: Yup.number().typeError("Ingrese un número"),
    }),

    mapPropsToValues: props => ({
        getIncident: props.getIncident,

    }),

    handleSubmit: (values, { setSubmitting }) => {
        let filter="";

        if ( values.appraiser_sent_since )
            filter += 'appraiser_sent_since='+values.appraiser_sent_since+'&';
        if ( values.appraiser_sent_until )
            filter += 'appraiser_sent_until='+values.appraiser_sent_until+'&';
        if ( values.office_entrance_since )
            filter += 'office_entrance_since='+values.office_entrance_since+'&';
        if ( values.office_entrance_until )
            filter += 'office_entrance_until='+values.office_entrance_until+'&';
        if ( values.internal_number )
            filter += 'internal_number='+values.internal_number+'&';
        if ( values.appraiser_id )
            filter += 'appraiser_id='+values.appraiser_id+'&';
        if ( values.state )
            filter += 'state[]='+values.state+'&';
        if ( values.insurer_id )
            filter += 'insurer_id='+values.insurer_id+'&';
        if ( values.crop )
            filter += 'crop='+values.crop+'&';

        setTimeout(() => {
            values.getIncident( 1, filter );
            setSubmitting(false);
        }, 1000);
    },
    displayName: 'MyForm',
});


const MyForm = props => {
    const classes = useStyles();
    const { handleClose, values, appraiser, setFieldValue, states, crops, insurers, handleSubmit } = props;
    return <div>
                <form onSubmit={handleSubmit}>
                    <DialogTitle style={{marginLeft: "15px"}} id="form-dialog-title">Filtrar</DialogTitle>
                    <Divider />
                    <DialogContent>
                    <div className={classes.root}>

                        <Grid container spacing={12}>
                            <Grid item xs={2} style={{marginTop:"15px"}}>
                                <h5>Ingreso al estudio</h5>
                            </Grid>
                            <Grid item xs={2}>
                                <Field
                                    type="date"
                                    name="office_entrance_since"
                                    label="Desde"
                                    component={TextField}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Field
                                    type="date"
                                    name="office_entrance_until"
                                    label="Hasta"
                                    component={TextField}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={12} style={{marginTop:"15px"}}>
                            <Grid item xs={2} style={{marginTop:"15px"}}>
                                <h5>Envío al tasador </h5>
                            </Grid>

                            <Grid item xs={2}>
                                <Field
                                    type="date"
                                    name="appraiser_sent_since"
                                    label="Desde"
                                    component={TextField}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Field
                                    type="date"
                                    name="appraiser_sent_until"
                                    label="Hasta"
                                    component={TextField}
                                    InputLabelProps={{ shrink: true }}

                                />
                            </Grid>

                        </Grid>
                        <Grid container spacing={12} style={{marginTop:"15px"}}>
                            <Grid item xs={2}>
                                <Field
                                    type="text"
                                    name="internal_number"
                                    label="Número Interno"
                                    value={values.internal_number}
                                    component={TextField}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl>
                                    <Field
                                        label="Tasador"
                                        name="appraiser_id"
                                        select
                                        onChange={setFieldValue}
                                        component={ TextField }
                                        style={{ width: 150 }}
                                        >
                                        {appraiser.map( item => (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl>
                                    <Field
                                        label="Estado"
                                        name="state"
                                        select
                                        onChange={setFieldValue}
                                        component={ TextField }
                                        style={{ width: 350 }}
                                        >
                                        {states.map( item => (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={12} style={{marginTop:"15px"}}>
                            <Grid item xs={2}>
                                <FormControl>
                                    <Field
                                        label="Compañia"
                                        name="insurer_id"
                                        select
                                        onChange={setFieldValue}
                                        component={ TextField }
                                        style={{ width: 150 }}
                                        >
                                        {insurers.map( item => (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl>
                                    <Field
                                        label="Cultivo"
                                        name="crop"
                                        select
                                        onChange={setFieldValue}
                                        component={ TextField }
                                        style={{ width: 150 }}
                                        >
                                        {crops.map( item => (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </FormControl>
                            </Grid>
                        </Grid>
                            <div className="row" style={{marginTop:"35px"}}>
                                <Button variant="contained" type="submit" color="secondary" style={{ margin:"10px", marginLeft:"28px", color:'#ffff', backgroundColor:"#605A73"}}>
                                    Confirmar
                                </Button>
                                <Button variant="contained" onClick={handleClose} style={{ margin:"10px"}}>
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </form>
        </div>;
}


const FilterModal = formikEnhancer(MyForm);
export default FilterModal;
