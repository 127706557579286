import React from 'react';
import { Divider, Button, Dialog, DialogContent, DialogTitle } from '../materialUi.js';
import { withFormik } from 'formik';
import TextField from "../FormikTextField";
import { Field } from 'formik';
import * as Yup from "yup";
import { SimpleSnackbar } from '../SimpleSnackbar.js';

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        name: Yup.string().required('Nombre requerido'),
        descripción: Yup.string(),
    }),

    mapPropsToValues: props => ({
        handleSubmitAdd: props.handleSubmitAdd,
        name: '',
        description: ''
    }),

    handleSubmit: (values, { setSubmitting }) => {
        const payload = {
            ...values,
        };
        setTimeout(() => {
            values.handleSubmitAdd( payload );
            setSubmitting(false);
        }, 1000);
    },
    displayName: 'MyForm',
});

const MyForm = props => {
    const {
        handleSubmit,
        setFieldValue,
        handleClose,
        errorModal,
    } = props;
    return (
        <Dialog
            open={true}
            onClose={handleClose}
            fullScreen={true}
        >
            {errorModal && <SimpleSnackbar message={errorModal}/>}
            <form onSubmit={handleSubmit}>
                <DialogTitle style={{marginLeft:"7px"}} id="form-dialog-title">NUEVA ASEGURADORA</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className="row">
                        <div className="col">
                            <Field
                                style={{width:"100%"}}
                                label="Nombre"
                                name="name"
                                onChange={setFieldValue}
                                variant="outlined"
                                component={TextField}
                            />
                        </div>
                    </div>
                    <div className="row" style={{marginLeft:"-5px"}} >
                        <Button variant="contained" type="submit" style={{margin:"5px"}}>
                            Confirmar
                        </Button>
                        <Button variant="contained" onClick={handleClose} style={{margin:"5px"}}>
                            Cancelar
                        </Button>
                    </div>
                    <div style={{marginBottom:"200px"}}></div>

                </DialogContent>
            </form>
        </Dialog>);
}


const AddModal = formikEnhancer(MyForm);
export default AddModal;
