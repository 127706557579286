import axios from 'axios';
import * as actionTypes from './actionTypes';
import { refresh } from '../../containers/getInstanceAxios.js';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = ( token, expires_at, roles, user_name, user_id ) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        expire: expires_at,
        roles: roles,
        user_name: user_name,
        user_id: user_id,
    };
};

export const authFail = (error) => {
    if ( error.response ) {
        return {
            type: actionTypes.AUTH_FAIL,
            error: error.response.data
        };
    }
    return { type: actionTypes.AUTH_FAIL }
};

export const logout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = ( expirationTime ) => {
    return dispatch => {
        setTimeout( ( ) => {
            dispatch( logout( ) )
        }, 360000 )//cambiar por resta entre fecha expiracion menos fecha actual- now()
    };
}

export const authCheckState = () => {
    return dispatch => {
        const user_id = localStorage.getItem( 'user_id' );
        const user_name = localStorage.getItem( 'user_name' );
        const token = localStorage.getItem( 'access_token' );
        const expires_at = localStorage.getItem( 'expires_at' );
        const roles = localStorage.getItem( 'roles' ) ? localStorage.getItem( 'roles' ).split(',') : [];
        if ( token ) {
            dispatch( authSuccess( token, expires_at, roles, user_name, user_id ));
        }
    };
};

export const auth = (user_name, password ) => {
    return dispatch => {
        dispatch(authStart());

        const instance = axios.create({
            baseURL: process.env.REACT_APP_API_ENDPOINT,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        const authData = {
            name: user_name,
            password: password
        };

        instance.post( 'login/', authData )
            .then( res => {
                localStorage.setItem( 'user_id', res.data.user_id );
                localStorage.setItem( 'user_name', res.data.user_name );
                localStorage.setItem( 'access_token', res.data.access_token );
                localStorage.setItem( 'expires_at', res.data.expires_at );
                localStorage.setItem( 'roles', res.data.roles );

                // refresh axios instance
                refresh();
                dispatch( authSuccess( res.data.access_token, res.data.expires_at, res.data.roles, res.data.user_name, res.data.user_id ) );

            })
            .catch( err => {
                dispatch( authFail( err ) );
            });
    };
};
