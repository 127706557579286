import React, { Component } from 'react';
import { Divider, Button, Dialog, DialogContent, IconButton, DialogTitle, Table, TableBody, TableCell,TableRow, Checkbox }
    from '../materialUi.js';
import TextField from "../FormikTextField";
import * as Yup from 'yup';
import { Field, Formik, Form, } from 'formik';
import Grid from '@material-ui/core/Grid';
import { handleCheckBoxGeneric } from '../TableFunctions.js';
import { handleDownloadFile } from '../../components/HandleDownloadFile/HandleDownloadFile.js';
import { instance } from '../getInstanceAxios.js';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Spinner from '../../components/Spinner/Spinner';
import { SimpleSnackbar } from '../SimpleSnackbar.js';
import UploadButton from '../../components/UploadButton/UploadButton.js';
import { file2formdata } from '../../components/UploadButton/File2FormData';

class AppraisalModal extends Component {

    state = {
        selectedAppraisal: [],
        isLoaded: false,
        message: null,
        files: this.props.initFiles
    }

    isSelectedAppraisal = id => this.state.selectedAppraisal.indexOf( id ) !== -1;

    handleCheckBoxAppraisal = ( event, id) => {
        let result = handleCheckBoxGeneric( event, id, this.state.selectedAppraisal );
        this.setState({ selectedAppraisal: result.selected });
    };

    getFiles = () => instance.get( 'incident/' + this.props.update_id + '/file?dir=result')
      .then(res => this.setState({files: res.data}));

    uploadFiles = (name, files) => {
      if (files && files.length > 0)
        return instance.post( 'incident/' + this.props.update_id + '/result', file2formdata( files ), { headers: { "Content-Type": "multipart/form-data" } })
          .then(res => this.getFiles())
          .catch(err => alert(err.response.data.message));
    }
    downloadFile = namefile => instance.get( '/incident/' + this.props.update_id + '/file?dir=result&name=' + namefile, { responseType: 'blob' } )
        .then( res => handleDownloadFile( res, namefile ) );

    handleSubmit = (values, { setSubmitting }) => {
        this.setState({ isLoaded:true });
        let payload={
            "email": values.email,
            "subject": values.subject,
            "message": values.message,
            "files":this.state.selectedAppraisal
        };

        instance.post( 'incident/'+ this.props.update_id + '/send' ,payload )
        .then( res => {
            this.props.onResetState( "Enviado" );
        })
        .catch( err => {
            this.props.onResetState( "ha ocurrido un error: " + err );
        });
    }

    render( ){
        const { handleClose, setFieldValue, canSendMail } = this.props;
        let name_files = this.state.files.map( et => ( { value: et, label: et } ) );
        return <Dialog
                open={true}
                onClose={handleClose}
                fullScreen={true}
            >
            <Formik
                onSubmit={this.handleSubmit}
                initialValues={{
                    email:"",
                    subject:"",
                    message:"",
                }}
                validationSchema={ Yup.object().shape({
                    email: Yup.string().email('Debe ingresar un email valido').required('Email requerido'),
                    subject: Yup.string().nullable().max( 60, "Máximo 60 caracteres" ),
                    message: Yup.string().nullable().max( 500, "Máximo 500 caracteres" ),
                })}
                render={(props) => (
                    <Form>
                        <DialogTitle style={{marginLeft: "2px"}} id="form-dialog-title">RESULTADO DE LA TASACIÓN</DialogTitle>
                        {this.state.isLoaded ? <Spinner/> : null}
                        <Divider />
                        <DialogContent>
                          {canSendMail && <div>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <Field
                                        type="email"
                                        label="Email"
                                        name="email"
                                        margin="normal"
                                        onChange={setFieldValue}
                                        component={TextField}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Field
                                        type="text"
                                        label="Asunto"
                                        name="subject"
                                        margin="normal"
                                        onChange={setFieldValue}
                                        component={TextField}
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Field
                                        type="text"
                                        name="message"
                                        label="Mensaje"
                                        component={TextField}
                                        margin="normal"
                                        rowsMax={3}
                                        rows={3}
                                        style={{
                                            width: '100%',
                                        }}
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                          </div>}
                            <Grid container spacing={2}>
                                <Grid item xs={2} style={{marginTop:"30px"}}>
                                    <h4>Adjuntar archivos</h4>
                                </Grid>
                            </Grid>
                            <Grid container xs={11} style={{margin:"25px"}}>
                                <UploadButton
                                  name="files"
                                  auto={true}
                                  getFiles={() => instance.get( 'incident/' + this.props.update_id + '/file?dir=result')}
                                  handleAdd={this.uploadFiles}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Table aria-labelledby="tableTitle">
                                    <TableBody>
                                        {name_files.map(n => {
                                            const isSelected = this.isSelectedAppraisal(n.value);
                                            return (
                                                <TableRow
                                                    style={{ backgroundColor: '#fcfcfc'}}
                                                    hover
                                                    onClick={event => this.handleCheckBoxAppraisal(event, n.value)}
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    key={n.first}
                                                    selected={isSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox checked={isSelected} />
                                                    </TableCell>
                                                    <TableCell align="left">{n.value}</TableCell>
                                                    <TableCell align="left" style={{padding:"0px"}}>
                                                        <IconButton aria-label="ver" onClick={() => this.downloadFile(n.value)}>
                                                            <VisibilityIcon fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Grid>
                            <div className="row" >
                                {canSendMail ? <><Button variant="contained" type="submit" color="secondary" style={{ margin:"10px", marginLeft:"28px", color:'#ffff', backgroundColor:"#605A73"}}>
                                    Enviar
                                </Button>
                                <Button variant="contained" onClick={handleClose} style={{ margin:"10px"}}>
                                    Volver
                                </Button></>
                                : <Button variant="contained" onClick={handleClose} style={{ margin:"10px"}}>
                                    Volver
                                </Button>}
                            </div>
                        </DialogContent>
                    </Form>
                )}
            />
        </Dialog>;
    }
}


export default (AppraisalModal);
