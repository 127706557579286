export const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
        ? {
            color: '#CFCFC',
            backgroundColor: '#CFCFC'
        }
        : {
            color: '#CFCFC',
            backgroundColor: '#CFCFC',
        },
    spacer: {
        flex: '0 0 1%',
    },
    actions: {
        color: "#fcfcfc",
    },
    header: {
        color: "#fcfcfc",
    },
    title: {
        flex: '0 0 auto',
    },
});

export const divStyle = {
    margin: "20px"
};

export const styles = theme => ({
    fab: {
        margin: theme.spacing.unit,
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
});
