import React from 'react';
import { Dialog,DialogContent,DialogTitle } from '../materialUi.js';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import Button from '@material-ui/core/Button';
import { SimpleSnackbar } from '../SimpleSnackbar.js';

export default ( props ) => {
    const { errorModal,
        markedPermission,
        handleClose,
        handleChange,
        handleSubmitAdd,
        name,
        handleChangeSelectPermission,
        permission,
        customIndexOf } = props;
    return <div>
            <Dialog
                fullScreen={true}
                open={true}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
            {errorModal && <SimpleSnackbar message={errorModal}/>}
                <ValidatorForm onSubmit={handleSubmitAdd}>
                    <DialogTitle style={{marginLeft:"18px"}} id="form-dialog-title">Agregar rol</DialogTitle>
                    <DialogContent>
                            <div className="col">
                                <div className="form-group">
                                    <TextValidator
                                        style={{width:"100%"}}
                                        id="name"
                                        name="name"
                                        value={name}
                                        label="Nombre del rol"
                                        onChange={handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        validators={["required"]}
                                        errorMessages={["El campo es obligatorio"]}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <h5>Permisos asociados</h5>
                                {permission.map(p => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    onChange={() => handleChangeSelectPermission(p)}
                                                    value={p.name}
                                                    key={p.id}
                                                    checked={customIndexOf( p, markedPermission ) !== -1}
                                              />
                                            }
                                            label={p.name}
                                        />
                                    );
                                })}
                            </div>
                        <div className="row" style={{marginLeft:"15px"}} >
                            <Button variant="contained" type="submit" style={{margin:"5px"}}>
                                Confirmar
                            </Button>
                            <Button variant="contained" onClick={handleClose} style={{margin:"5px"}}>
                                Cancelar
                            </Button>
                        </div>
                    </DialogContent>
                </ValidatorForm>
            </Dialog>
        </div>;
}
