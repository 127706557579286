/* eslint-disable no-use-before-define */
import React from 'react';
import { Switch, FormControlLabel, FormControl, MenuItem, Divider, Button, Dialog, DialogTitle } from '../materialUi.js';
import { withFormik } from 'formik';
import TextField from "../FormikTextField";
import { Field } from 'formik';
import * as Yup from "yup";
import { SimpleSnackbar } from '../SimpleSnackbar.js';
import Grid from '@material-ui/core/Grid';
import MyAutocomplete from '../../components/Autocomplete/Autocomplete.js';
import MySelect from './MySelect.js'
import UploadButton from '../../components/UploadButton/UploadButton.js';
import AddLocation from '../../components/AddLocation/AddLocation.js';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        coinsurer_company: Yup.string().max(30, 'Máximo 30 caracteres').nullable(),
        contact_name: Yup.string().required('Selección requerida').max(30, 'Máximo 30 caracteres'),
        countryside_name: Yup.string().max(30, 'Máximo 30 caracteres').nullable(),
        crop: Yup.string().required('Selección requerida').max(20, 'Máximo 20 caracteres'),
        event_type: Yup.array().nullable().required('Selección requerida')
        .min(1, 'Selección requerida')
        .of( Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
        ),
        franchise_value: Yup.number().max(255, 'Máximo 255').nullable().typeError('Ingrese un numero'),
        hectares_number: Yup.number().typeError('Selección requerida'),
        incident_date: Yup.date().required("Debe ingresar una fecha"),
        incident_number: Yup.string().max(30, 'Máximo 30 caracteres').nullable(),
        insured_name: Yup.string().required('Selección requerida').max(50, 'Máximo 50 caracteres'),
        insurer_company: Yup.string().required('Selección requerida'),
        insurer_amount:Yup.number().nullable().typeError('Ingrese un numero'),
        lot_name: Yup.string().max(30, 'Máximo 30 caracteres').nullable(),
        observations: Yup.string().max(1000, 'Máximo 1000 caracteres').nullable(),
        office_entrance: Yup.date().required("Debe ingresar una fecha"),
        phone: Yup.string().required('Selección requerida').matches(phoneRegExp, 'Número ingresado no valido').max(30, 'Máximo 30 caracteres'),
        policy_number: Yup.string().max(30, 'Máximo 30 caracteres').nullable(),
        appraisers: Yup.array().nullable().required('Selección requerida')
            .min(1, 'Selección requerida')
            .of( Yup.object().shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
            })
            ),
    }),


    mapPropsToValues: props => ({
        handleSubmitAdd: props.handleSubmitAdd,
        insurer_company:"",
        policy_number:null,
        incident_number:null,
        incident_date:"",
        office_entrance:"",
        event_type:[],
        lot_name: "",
        countryside_name: "",
        contact_name:"",
        insured_name:"",
        phone:"",
        crop:props.cropValue,
        insurer_amount:null,
        insurer_amount_unit:null,
        franchise_type:"",
        franchise_value:null,
        coinsurer_company:"",
        previous_damage:"",
        hectares_number:null,
        appraisers: [],
        location: "",
        observations: "",
        upload: []
    }),
    enableReinitialize: true,

    handleSubmit: (values, { setSubmitting }) => {

        let payload = {};
        if (values.franchise_value) {
            payload = {
                "franchise_value": values.franchise_value,
            }
        }
        if (values.insurer_amount) {
            payload = {
                ...payload,
                "insurer_amount": values.insurer_amount,
            }
        }

        if ( values.insurer_amount_unit )
            payload = { ...payload, "insurer_amount_unit": values.insurer_amount_unit };

        payload = {
            ...payload,
            "event_type": values.event_type.map(t => t.value),
            "insurer_id":values.insurer_company,
            "policy_number":values.policy_number,
            "incident_number":values.incident_number,
            "incident_date":values.incident_date,
            "office_entrance":values.office_entrance,
            "countryside_name": values.countryside_name,
            "lot_name": values.lot_name,
            "contact_name":values.contact_name,
            "insured_name":values.insured_name,
            "phone":values.phone,
            "franchise_type": values.franchise_type,
            "coinsurer_company":values.coinsurer_company,
            "previous_damage":values.previous_damage,
            "hectares_number":values.hectares_number,
            "record": values.record,
            "state": values.state,
            "uploadFiles": values.upload,
            "appraisers": values.appraisers.map(t => t.value),
            "crop": values.crop,
            "observations": values.observations,
        };
        setTimeout(() => {
            values.handleSubmitAdd( payload );
            setSubmitting(false);
        }, 1000);
    },
    displayName: 'MyForm',
});

const MyForm = props => {
    const {
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        handleClose,
        values,
        errorModal,
        insurer_company,
        crop,
        franchise_type,
        insurer_amount_unit,
        event_type,
        previous_damage,
        appraiser,
        location,
        department,
        province,
        getDepartment,
        getLocation,
        setLocationToUpdate,
    } = props;
    let newEvent = event_type.map( et => ( { value: et, label: et } ) );
    let newAppraiser = appraiser.map( et => ( { value: et.id, label: et.name } ) );
    return (

        <Dialog
            open={true}
            onClose={handleClose}
            fullScreen={true}
        >
            {errorModal && <SimpleSnackbar message={errorModal}/>}
            <form onSubmit={handleSubmit}>
                <DialogTitle style={{marginLeft:"3px"}} id="form-dialog-title">NUEVO SINIESTRO</DialogTitle>
                <Divider/>
                <Grid container xs={10} style={{ margin:"25px"}} direction={'row'}>
                    <Grid item xs={3}>
                        <FormControl>
                            <Field
                                type="text"
                                label="Nombre de contacto"
                                name="contact_name"
                                onChange={setFieldValue}
                                component={TextField}
                                style={{ width: 150 }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl>
                            <Field
                                label="Nombre asegurado"
                                type="text"
                                name="insured_name"
                                onChange={setFieldValue}
                                component={TextField}
                                style={{ width: 150 }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl>
                            <Field
                                label="Telefono"
                                type="text"
                                name="phone"
                                onChange={setFieldValue}
                                component={TextField}
                                style={{ width: 150 }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <MySelect
                            newItems={newEvent}
                            title="Tipo de eventos"
                            name="event_type"
                            value={values.event_type}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            error={props.errors.event_type}
                            touched={props.touched.event_type}
                            placeholder={"Puede ingresar multiples tipos de eventos"}

                        />
                    </Grid>

                </Grid>
                <Divider/>
                <Grid container xs={11} style={{margin:"25px"}} direction={'row'} spacing={2}>
                    <Grid item xs={12} sm={6} md={2} spacing={3}>
                        <FormControl>
                            <Field
                                label="Compañia aseguradora"
                                name="insurer_company"
                                select
                                onChange={setFieldValue}
                                component={ TextField }
                                style={{ maxWidth: '240px', width: '220px' }}
                            >
                                {insurer_company.map( item => (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Field>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} spacing={3}>
                        <FormControl>
                            <Field
                                label="Número de poliza"
                                type="text"
                                name="policy_number"
                                onChange={setFieldValue}
                                component={TextField}
                                style={{ maxWidth: '160px', width: '100%' }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} spacing={3}>
                        <FormControl>
                            <Field
                                label="Número de siniestro"
                                type="text"
                                name="incident_number"
                                onChange={setFieldValue}
                                component={TextField}
                                style={{ maxWidth: '160px', width: '100%' }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} spacing={3}>
                        <Field
                            label="Fecha del siniestro"
                            type="date"
                            name="incident_date"
                            component={TextField}
                            style={{ maxWidth: '160px', width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} spacing={3}>
                        <Field
                            label="Ingreso al estudio"
                            type="date"
                            name="office_entrance"
                            style={{ maxWidth: '160px', width: '100%' }}
                            component={TextField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <Divider/>

                <Grid container xs={11} style={{ margin:"25px"}} direction={'row'}>
                    <Grid item xs={2}>
                        <FormControlLabel
                            label="Agregar cultivo"
                            style={{ width: 200, marginTop:"20px" }}
                            control={
                                <Switch
                                    onChange={() => props.handleChangeSwitchCrop()}
                                    value={ props.addCrop }
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        { props.addCrop ?
                            <FormControl>
                                <Field
                                    type="text"
                                    label="Agregue el nuevo cultivo"
                                    name="crop"
                                    onChange={setFieldValue}
                                    component={TextField}
                                    style={{ width: 200 }}
                                />
                            </FormControl>
                            :
                            <FormControl>
                                <Field
                                    style={{ width:"180px"}}
                                    name="crop"
                                    select
                                    label="Elija el cultivo"
                                    component={TextField}
                                    >
                                    {crop.map( c => (
                                        <MenuItem value={c} key={c}>
                                            {c}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </FormControl>
                        }
                    </Grid>


                    <Grid item xs={2}>
                        <FormControl>
                            <Field
                                label="Nombre del campo"
                                type="text"
                                name="countryside_name"
                                onChange={setFieldValue}
                                component={TextField}
                                style={{ width: 150 }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <Field
                                label="Nombre de lote"
                                type="text"
                                name="lot_name"
                                onChange={setFieldValue}
                                component={TextField}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container xs={11} style={{margin:"25px"}}>
                    <Grid item xs={2}>
                        <FormControl>
                            <Field
                                label="Suma asegurada(Ha)"
                                type="text"
                                name="insurer_amount"
                                onChange={setFieldValue}
                                component={TextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <Field
                                label="Unidad"
                                name="insurer_amount_unit"
                                select
                                onChange={setFieldValue}
                                component={ TextField }
                                style={{ width: 70 }}
                                >
                                {insurer_amount_unit.map( item => (
                                    <MenuItem value={item} key={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Field>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl>
                            <Field
                                label="Tipo de franquicia"
                                name="franchise_type"
                                select
                                onChange={setFieldValue}
                                component={ TextField }
                                style={{ width: 150 }}
                                >
                                {franchise_type.map( item => (
                                    <MenuItem value={item} key={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Field>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl>
                            <Field
                                label="Valor franquicia (%)"
                                type="text"
                                name="franchise_value"
                                onChange={setFieldValue}
                                component={TextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl>
                            <Field
                                label="Co-aseguradora"
                                type="text"
                                name="coinsurer_company"
                                onChange={setFieldValue}
                                component={TextField}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container xs={10} style={{margin:"25px"}}>
                    <Grid item xs={3}>
                        <FormControl>
                            <Field
                                label="Daño anterior"
                                name="previous_damage"
                                select
                                onChange={setFieldValue}
                                component={ TextField }
                                style={{ width: 100 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                >
                                {previous_damage.map( item => (
                                    <MenuItem value={item} key={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Field>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl>
                            <Field
                                label="Cantidad de hectareas"
                                type="text"
                                name="hectares_number"
                                onChange={setFieldValue}
                                component={TextField}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} style={{marginLeft:"20px"}}>
                        <MySelect
                            newItems={newAppraiser}
                            title="Tasador"
                            name="appraisers"
                            value={values.appraisers}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            error={props.errors.appraisers}
                            touched={props.touched.appraisers}
                            placeholder={"Puede ingresar multiples tasadores"}

                        />
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container xs={11} style={{margin:"25px"}}>
                    <Grid item xs={2}>
                        <MyAutocomplete
                            label="Provincia"
                            disabled={false}
                            options={province}
                            onChange={getDepartment}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <MyAutocomplete
                            label="Departamento"
                            disabled={false}
                            options={department}
                            onChange={getLocation}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <MyAutocomplete
                            label="Localidad"
                            disabled={false}
                            options={location}
                            onChange={setLocationToUpdate}
                        />
                    </Grid>
                    <Grid item xs={2} style={{marginTop:"25px"}}>
                        <AddLocation provinces={province} />
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container xs={8} style={{margin:"25px"}}>
                    <Field
                        type="content"
                        name="observations"
                        label="Observaciones"
                        component={TextField}
                        margin="normal"
                        rowsMax={3}
                        rows={2}
                        style={{ width: '100%' }}
                        multiline
                    />
                </Grid>
                <Divider/>
                <Grid container xs={11} style={{margin:"25px"}}>
                    <UploadButton
                      name="upload"
                      handleAdd={setFieldValue}
                      handleDelete={true}
                      listFiles={true}
                    />
                </Grid>
                <Grid container xs={11} style={{marginBottom: "30px"}}>
                    <Grid container xs={6} style={{marginBottom: "30px"}}>
                        <Button size="large" color="primary" variant="contained" type="submit" style={{marginRight:"25px"}}>
                            Confirmar
                        </Button>
                        <Button size="large" color="default" variant="contained" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Dialog>);
}


const AddModal = formikEnhancer(MyForm);
export default AddModal;
