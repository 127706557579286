import React, { Component } from 'react';
import Table from "../../components/Table/Table";
import { instance } from '../getInstanceAxios.js';
import Spinner from '../../components/Spinner/Spinner'
import AddModal  from './AddModal';
import UpdateModal  from './UpdateModal';
import { handleAllCheckBoxGeneric, handleCheckBoxGeneric, handleChangePageGeneric } from '../TableFunctions.js';

class Insurer extends Component {

    state = {
        data: [],
        error: null,
        isLoaded: false,
        openAdd: false,
        openUpdate: false,
        errorModal: null,
        pagination: {},
        selected: [],
        selectAll: false,
        idInsurer:null,
        name:"",
    }

    componentDidMount() {
        this.getInsurer();
    }

    getInsurer = ( page=1 ) => {
        instance.get('insurer?page='+page)
        .then( res => {
            const { total, current_page, per_page, data } = res.data;
            var genericData = data.map( item => {
                return { first: item.id, second: item.name, third:item.id };
            });
            this.setState({
                data:genericData,
                pagination: {
                    total,
                    currentPage: current_page,
                    rowsPerPage: per_page
                },
                isLoaded:true,
                selected:[],
                selectAll:false
            });
        })
        .catch( err => {
            this.setState({ error:err, isLoaded:true });
        });
    }

    /**
        Metodo encargado de activar el modal de Agregar
     **/
    onAdd = () => {
        this.setState({ openAdd: true, errorModal:null });
    }

    /**
    Metodo a cargo de crear el rol con sus permisos asociados
    **/
    handleSubmitAdd = ( event ) => {
        const data = {
            name: event.name,
        };

        instance.post( 'insurer/', data )
        .then( res => {
            this.setState({
                errorModal:"Creada",
                openAdd:null,
                selected:[],
                selectAll:false,
           });
            this.getInsurer( );
        })
        .catch( err => {
            this.setState({ errorModal:err });
        });
    }

    // onUpdate = ( idArray ) => {
    //     const promises = [];
    //     promises.push( instance.get('/insurer/' + idArray[0] ) );
    //     Promise.all( promises ).then( res => {
    //         console.log(res.data);
    //         this.setState({ cleanSelection: false, data:res.data, isLoaded:true,
    //                 },  () => { this.setState({ openUpdate: true, errorModal:null })});
    //                 return;
    //     });
    // }


    onUpdate = ( idArray ) => {
        const promises = [];
        promises.push( instance.get('/insurer/' + idArray[0] ) );
        Promise.all( promises ).then( res => {
            for (let i = 0; i < this.state.data.length; i++) {
                if ((this.state.data[i].first)===idArray[0]) {
                    this.setState({
                        cleanSelection: false,
                        errorModal: null,
                        name: this.state.data[i].second,
                        idInsurer : idArray[0]
                    },  () => { this.setState({openUpdate: true })});
                    return;
                }
            }
        });
    }
    /**
    Metodo a cargo de actualizar el rol con sus permisos asociados
    **/
    handleSubmitUpdate = ( event ) => {

        const data = {
            name: event.name,
        };

        const path = 'insurer/'+ this.state.selected;
        instance.put(path, data)
        .then( res => {
            this.setState({
                selected:[],
                selectAll:false,
                openUpdate:null,
                cleanSelection: true,
                errorModal:"Aseguradora modificada correctamente",
            });
            this.getInsurer( );
        })
        .catch( err => {
            this.setState({ error:err, openUpdate:null });
        });
    }

    /**
    Metodo a cargo de cerrar el modal
    **/
    handleClose = () => {
        this.setState({
            errorModal:null,
            openUpdate: false,
            openAdd: false,
            name:null,
            selected:[],
            selectAll:false,
        });
    }

    /**
        Metodo encargado de eliminar un elemento o muchos en el backend
     **/
    onDelete = ( idArray ) => {
        for (var i = 0; i < idArray.length; i++) {
            instance.delete('insurer/'+ idArray[i])
                .then( res => {
                    this.setState({ errorModal:"Aseguradora/s eliminada/s correctamente" });
                    this.getInsurer();
                })
                .catch( err => {
                    process.env.NODE_ENV !== 'production' && console.log( err );
                });
        }
    }

    handleAllCheckBox = event => {
        let result = handleAllCheckBoxGeneric( event, this.state.data );
        this.setState({ selectAll:result.selectAll, selected:result.selected })
    };

    handleCheckBox = ( event, id) => {
        let result = handleCheckBoxGeneric( event, id, this.state.selected );
        this.setState({ selectAll: false, selected: result.selected });
    };

    handleChangePage = (event, page) => {
        handleChangePageGeneric(event, page, this.getInsurer )
    };

    isSelected = id => this.state.selected.indexOf( id ) !== -1;

    render () {
        const title = "Aseguradoras";
        const { error,
                data,
                isLoaded,
                openAdd,
                openUpdate,
                errorModal,
                pagination,
                cleanSelection,
                name,
              } = this.state;
        const roles = this.props.roles;
        const rows = [{ id: 'name', numeric: false, disablePadding: true, label: 'Nombre' },
                      { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
                      { id: '', numeric: false, disablePadding: false, label: '' },
                      { id: '', numeric: false, disablePadding: false, label: '' },
                      { id: '', numeric: false, disablePadding: false, label: '' }];

        if (!isLoaded) {
            return <div> <Spinner /></div>
        } else if(error){
            return <div><h1>{error.message}</h1></div>
        } else {
            return <div>
                <Table
                    viewTable= {true}
                    title={title}
                    rows={rows}
                    data={data}
                    pagination={pagination}
                    errorModal={errorModal}
                    cleanSelection={cleanSelection}
                    onChangePage={this.getInsurer}
                    onDelete= { this.onDelete }
                    onAddItem= { this.onAdd }
                    onUpdate={ this.onUpdate }
                    canCreate={roles.includes('insurer.create')}
                    canRead={roles.includes('incident.read')}
                    handleAllCheckBox={this.handleAllCheckBox}
                    handleCheckBox={this.handleCheckBox}
                    handleChangePage={this.handleChangePage}
                    isSelected={this.isSelected}
                    selected={this.state.selected}
                    selectAll={this.state.selectAll}
                />
                {openAdd &&
                    <AddModal
                        handleChange={this.handleChange}
                        handleClose={this.handleClose}
                        handleSubmitAdd={this.handleSubmitAdd}
                        errorModal={errorModal}
                    />
                }
                {openUpdate &&
                    <UpdateModal
                        errorModal={errorModal}
                        handleClose={this.handleClose}
                        handleChange={this.handleChange}
                        name={name}
                        handleSubmitUpdate={this.handleSubmitUpdate}
                    />
                }
            </div>
        }
    }
}

export default Insurer;
