import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Grid from '@material-ui/core/Grid';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';


const styles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    display: 'none',
  },
});

// PROPIEDADES
// name hace referencia al elemento a actualizar con handleAdd qe es el setFieldValue( name, value )
// getFiles return files [ { *name: "namefile", file: File } ] el segundo es opcional, refiere a archivo nuevo.
// handleDelete: elimina algun archivo del servidor
// handleAdd: agrega archivo/s
// handleDownload:
//
// onRemoveItem: elimina el elemento de la lista, en el caso que este en el server llama a handleDelete

class UploadButton extends Component {
    state = {
        files: []
    }

    getFiles = () => {
        this.props.getFiles().then( res => {
            let files = res.data.map( name => ({ name: name }) );
            this.setState({ files });
        }).catch( err => { console.log( "error" ) });
    }

    componentDidMount() {
        if ( this.props.getFiles ) {
            this.getFiles();
        }
    }

    shouldComponentUpdate( nextProp, nextState ) {
        if ( this.props && this.state.files === nextState.files )
            return false;

        return true;
    }

    handleChangeFiles = files => {
        const allFiles = [].concat( this.state.files );
        const allFilenames = allFiles.map(f => f.name);
        let extension;
        for ( let file of files ) {
            if (allFilenames.includes(file.name)) {
              alert( `El archivo ${file.name} ya existe` );
              // this.setState({files: []});
            } else {
              allFiles.push( file );
              extension = file.name.substr( file.name.lastIndexOf( '.' ) + 1 );
              if ( extension === "kmz" )
                  alert( "Para visualizarlo en la aplicación se requiere subir archivos KML en vez de KMZ" );
            }
        }
        this.setState({ files: allFiles });
        const uploadFiles = allFiles.filter( file => "size" in file );
        this.props.handleAdd( this.props.name, uploadFiles );
        if (this.props.auto)
            this.getFiles();
    }

    handleRemoveItem = ( file, index ) => {
        if ( !window.confirm( "Se eliminará el archivo. ¿Desea continuar?" ) )
            return;
        const isUploaded = !( "size" in file );
        if ( isUploaded )
            this.props.handleDelete( file.name ); // elimino del server

        let filesUpdated = [].concat( this.state.files );
        filesUpdated.splice( index, 1 );
        this.setState({ files: filesUpdated });
    }

    render() {
        const { classes, name, handleDownload, handleDelete, listFiles } = this.props;
        return (
            <Grid xs={11}>
                <div className={classes.root}>
                    <input className={classes.input} id={name} name={name} type="file" multiple onChange={e => this.handleChangeFiles( e.target.files )} />
                    <label htmlFor={name}>
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <CloudUploadIcon fontSize="large"/>
                        </IconButton>
                        Subir archivos
                    </label>
                </div>
                <List dense={true} style={{marginLeft:"25px"}}>
                  { listFiles && this.state.files.map( ( file, index ) =>
                    <ListItem button>
                      <ListItemIcon>
                        <AttachFileIcon/>
                      </ListItemIcon>
                      <ListItemText
                        primary={file.name}
                      />
                      { handleDownload && <ListItemSecondaryAction style={{marginRight: "30px"}}>
                        <IconButton edge="start" aria-label="download" color="primary" onClick={ () => handleDownload( file.name ) } >
                          <SaveIcon fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>}
                      { handleDelete && <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" color="secondary" onClick={ () => this.handleRemoveItem( file, index ) } >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>}
                    </ListItem>,
                  )}
                </List>
            </Grid>
        );
    }
}

export default withStyles( styles )( UploadButton )
