import React, { Component } from 'react';
import Table from "../../components/Table/Table";
import { instance } from '../getInstanceAxios.js';
import Spinner from '../../components/Spinner/Spinner'
import UpdateModal from './UpdateModal';
import AddModal  from './AddModal';
import { handleAllCheckBoxGeneric, handleCheckBoxGeneric, handleChangePageGeneric } from '../TableFunctions.js';

class User extends Component {

    state = {
        data: [],
        userData: [],
        rolesOptions: [],
        error: null,
        isLoaded: false,
        openAdd: false,
        openUpdate: false,
        errorModal:null,
        pagination: {},
        cleanSelection: false,
        selected: [],
        selectAll: false,
    }


    componentDidMount() {
        this.getUsers();
        this.getRoles();
    }

    getRoles = () => {
        instance.get( 'role/' )
        .then( res => {
            this.setState({ rolesOptions: res.data });
        })
        .catch( err => {
            this.setState({ error:err });
        });
    }

    getUsers = ( page=1 ) => {
        instance.get('user?page='+page)
        .then( res => {
            const { total, current_page, per_page, data } = res.data;
            var genericData = data.map( item => {
                return { first: item.id, second: item.email, third: item.name };
            });
            //Chequeo si existen roles para mostrar
            for (var i = 0; i < genericData.length; i++) {
                if (data[i].roles[0]) {
                    genericData[i].fourth = data[i].roles[0].name;
                }else {
                    genericData[i].fourth = "Sin rol";
                }
            }
            this.setState({ data:genericData, pagination: { total, currentPage: current_page, rowsPerPage: per_page }, isLoaded:true });
        })
        .catch( err => {
            this.setState({ error:err, isLoaded:true });
        });
    }

    handleClose = () => {
        this.setState({
            userData: [],
            errorModal: null,
            openUpdate: false,
            openAdd: false,
        });
    }

    handleChange = event => {
        this.setState({
            [ event.target.id ]: event.target.value
        });
    }

    handleSubmitAdd = ( user ) => {
        const userData = {
            name: user.name,
            email: user.email,
            password: user.password,
            markedRoles: user.roles,
        };
        instance.post( 'user/', userData )
            .then( res => {
                this.setState({ openAdd: null, errorModal:"Creado" });
                this.getUsers();
            })
            .catch( errorModal => {
                let err = "";
                if ( errorModal.response.status === 400 ) {
                    err = "Un campo está repetido"
                }
                this.setState({ errorModal:err });
            });
    }

    handleSubmitUpdate = ( user ) => {
        const userData = {
            name: user.name,
            email: user.email,
            password: user.password,
            markedRoles: user.roles,
            cleanSelection: true,
        };

        instance.put( 'user/'+ user.id, userData )
            .then( res => {
                this.setState({ openUpdate: null, errorModal:"Actualizado" });
                this.getUsers();
            })
            .catch( error => {
                let err = "";
                if ( error.response.status === 400 ) {
                    err = "Un campo está repetido"
                }
                this.setState({ errorModal:err, openUpdate: null });
        });
    }

    onAddItem = () => {
        this.setState({ openAdd: true, errorModal:null });
    }

    onDelete = async userSelection => {
        for ( let i=0; i<userSelection.length; i++ ) {
            try {
                await instance.delete( 'user/'+ userSelection[i] )
                this.setState({ errorModal:"Usuario/s eliminado/s correctamente" });
            } catch ( error ) {
                process.env.NODE_ENV !== 'production' && console.log( error );
            }
        }
        this.getUsers();
    }

    onUpdate = async userSelection => {
        const userId = userSelection[0];
        let roles = [];
        let user = {};
        try {
            roles = await instance.get( '/user/'+ userId +'/role' );
            user = await instance.get( '/user/'+ userId );
        } catch ( error ) {
            this.setState({ error });
        }

        const userData = {
            id: user.data.id,
            name: user.data.name,
            email: user.data.email,
            roles: roles.data,
        }
        this.setState({ userData }, () => { this.setState( { errorModal:null, cleanSelection: false, openUpdate: true }) });
    }

    handleAllCheckBox = event => {
        let result = handleAllCheckBoxGeneric( event, this.state.data );
        this.setState({ selectAll:result.selectAll, selected:result.selected })
    };

    handleCheckBox = ( event, id) => {
        let result = handleCheckBoxGeneric( event, id, this.state.selected );
        this.setState({ selectAll: false, selected: result.selected });
    };

    handleChangePage = (event, page) => {
        handleChangePageGeneric(event, page, this.getUsers )
    };

    isSelected = id => this.state.selected.indexOf( id ) !== -1;

    render () {
        const title = "Usuarios";
        const { errorModal, error, rolesOptions, userData, data, isLoaded, openAdd, openUpdate, pagination, cleanSelection } = this.state;
        const roles = this.props.roles;
        const rows = [
            { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
            { id: 'name', numeric: false, disablePadding: true, label: 'Nombre' },
            { id: 'rol', numeric: false, disablePadding: false, label: 'Roles' },
            { id: '', numeric: false, disablePadding: false, label: '' },
            { id: '', numeric: false, disablePadding: false, label: '' },
            { id: '', numeric: false, disablePadding: false, label: '' }
        ];
        if (!isLoaded) {
            return <div> <Spinner /></div>
        } else if(error){
            return <div><h1>{error.message}</h1></div>
        } else {
            return <div>
                <Table
                    title={title}
                    rows={rows}
                    data={data}
                    cleanSelection={cleanSelection}
                    viewTable= {true}
                    pagination={pagination}
                    errorModal={errorModal}
                    onChangePage={this.getUsers}
                    onUpdate={ this.onUpdate }
                    onDelete={ this.onDelete }
                    onAddItem={ this.onAddItem }
                    canCreate={roles.includes('userAdministration')}
                    canDelete={roles.includes('userAdministration')}
                    handleAllCheckBox={this.handleAllCheckBox}
                    handleCheckBox={this.handleCheckBox}
                    handleChangePage={this.handleChangePage}
                    isSelected={this.isSelected}
                    selected={this.state.selected}
                    selectAll={this.state.selectAll}
                />
                {openUpdate &&
                    <UpdateModal
                        userData={userData}
                        handleChange={this.handleChange}
                        handleClose={this.handleClose}
                        handleSubmitUpdate={this.handleSubmitUpdate}
                        rolesOptions={rolesOptions}
                        errorModal={errorModal}
                    />
                }
                {openAdd &&
                    <AddModal
                        handleChange={this.handleChange}
                        handleClose={this.handleClose}
                        handleSubmitAdd={this.handleSubmitAdd}
                        rolesOptions={rolesOptions}
                        errorModal={errorModal}
                    />
                }
            </div>
        }
    }
}

export default User;
