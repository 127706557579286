import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import LoginClean from "./containers/LoginClean/LoginClean";
import HeaderMenu from "./components/HeaderMenu/HeaderMenu";
import * as actions from './store/actions/index';
import User from "./containers/User/User";
import Role from "./containers/Role/Role";
import Insurer from "./containers/Insurer/Insurer";
import Incident from "./containers/Incident/Incident";
import Setting from "./components/Setting/Setting";

class App extends Component {

    componentDidMount () {
        this.props.onTryAutoSignup();
    }

    render() {
        const roles = this.props.roles;
        const user_id = this.props.userId;
        const user_name = this.props.user_name;
        return (
            <div>
                { this.props.isAuthenticated
                    ? <div>
                        <style>{'body { background-color: #CFCFC; }'}</style>
                        <HeaderMenu
                            userName = {this.props.user_name}
                            role={roles.includes('userAdministration')}
                            user={roles.includes('userAdministration')}
                            incident={roles.includes('incident.read')}
                            insurer={roles.includes('insurer.read')}
                        />
                        <Switch>
                            <Route exact path="/" render={() => roles.includes('userAdministration') ? (<Redirect to="/user" />) : (<Redirect to="/incident" />) } />
                            <Route path="/role" exact component={() => roles.includes('userAdministration') ? <Role roles={roles} /> : <Redirect to="/" /> } />
                            <Route path="/user" exact component={() => roles.includes('userAdministration') ? <User roles={roles} /> : <Redirect to="/" /> } />
                            <Route path="/setting" exact component={() => roles.includes('userAdministration') ? <Setting roles={roles} /> : <Redirect to="/" /> } />
                            <Route path="/insurer" exact component={() => roles.includes('insurer.read') ? <Insurer roles={roles} /> : <Redirect to="/" /> } />
                            <Route path="/incident" exact component={() => roles.includes('incident.read') ? <Incident userName={user_name} user_id={user_id} roles={roles} /> : <Redirect to="/" /> } />
                        </Switch>
                      </div>
                    : <div>
                        <LoginClean error={this.props.error} />
                     </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        loading: state.auth.loading,
        error: state.auth.error,
        roles: state.auth.roles,
        user_name: state.auth.user_name,
        userId: state.auth.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch( actions.authCheckState() )
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) );
