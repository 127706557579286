import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class Switches extends React.Component {
    handleChange = rolId => {
        let markedRoles = [...this.props.value];
        let index = markedRoles.indexOf( rolId );
        if ( index > -1 ) {
            markedRoles.splice( index, 1 );
            if ( rolId === this.props.cargadorId ) this.props.onChange( 'sources', [] );
        } else {
          markedRoles.push( rolId );
        }
        this.props.onChange('roles', markedRoles);
    };
    handleBlur = () => {
        this.props.onBlur('roles', true);
    };
    render() {
        return (
            <div style={{ margin: '1rem 0' }}>
                { this.props.roles.map( rol => (
                    <FormControlLabel
                        key={ rol.id }
                        style={{ margin: "20px" }}
                        control = {
                            <Switch
                              checked={ this.props.value.includes(rol.id) }
                              onChange={ ( ) => this.handleChange(rol.id) }
                              onBlur={ this.handleBlur }
                            />
                        }
                        label={ rol.name }
                    />))
                }
                { this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error }</div>
                )}
        </div>
        );
    }
}
export default Switches;
