import React from 'react';
import PropTypes from 'prop-types';
import TableToolbarGeneric from './TableToolbarGeneric';
import TableHeadGeneric from './TableHeadGeneric';
import { withStyles, Table, TableBody, TableCell, TablePagination, TableRow, Paper, Checkbox, VisibilityIcon, IconButton} from '../../containers/materialUi.js';
import { SimpleSnackbar } from '../../containers/SimpleSnackbar.js';

function MainTable( props ) {

    const { selected, selectAll, classes,
         rows, data, title, pagination, canCreate,
         viewTable, errorModal, canRead, canDelete } = props;
    const { total, rowsPerPage } = pagination;
    const currentPage = pagination.currentPage-1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, total - currentPage * rowsPerPage);

    return (
        <div>
            <Paper className={classes.root}>
                {errorModal && <SimpleSnackbar message={errorModal}/>}

                <TableToolbarGeneric
                    canCreate={canCreate}
                    canRead={canRead}
                    canDelete={canDelete}
                    title={title}
                    onUpdate={props.onUpdate}
                    onAddItem={props.onAddItem}
                    onDelete={props.onDelete}
                    numSelected={selectAll ? total : selected.length}
                    checked={selected}
                    selectAll={selectAll}
                    onFilter={props.onFilter}
                />
                { viewTable &&
                    <div>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <TableHeadGeneric
                                    numSelected={selectAll ? data.length : selected.length}
                                    onSelectAllClick={ props.handleAllCheckBox }
                                    rowCount={data.length}
                                    rows={rows}
                                />
                                <TableBody>
                                    {data.map(n => {
                                        const isSelected = props.isSelected(n.first);
                                        return (
                                            <TableRow
                                                style={{ backgroundColor: '#fcfcfc'}}
                                                hover
                                                onClick={event => props.handleCheckBox(event, n.first)}
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={n.first}
                                                selected={isSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isSelected} />
                                                </TableCell>
                                                <TableCell align="left">{n.second}</TableCell>
                                                <TableCell align="left">{n.third}</TableCell>
                                                <TableCell align="left">{n.fourth}</TableCell>
                                                <TableCell align="left">{n.fiveth}</TableCell>
                                                <TableCell align="left">{n.sixth}</TableCell>
                                                <TableCell align="left">{n.seventh}</TableCell>
                                                <TableCell align="left" style={{padding:"0px"}}>
                                                    {n.seventh === "TASADO" &&
                                                        <IconButton aria-label="ver" onClick={() => props.onAppraisal(n.first)}>
                                                            <VisibilityIcon fontSize="small" />
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            style={{ backgroundColor: '#cfcfc'}}
                            rowsPerPageOptions={[9]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            backIconButtonProps={{
                            'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                            'aria-label': 'Next Page',
                            }}
                            onChangePage={props.handleChangePage}
                        />
                    </div>
                }
            </Paper>
        </div>
    );
}

MainTable.propTypes = {
    classes: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    rows: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onAddItem:PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    errorModal: PropTypes.string,
};

export default withStyles()(MainTable);
