import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PeopleIcon from '@material-ui/icons/People';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SecurityIcon from '@material-ui/icons/Security';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListIcon from '@material-ui/icons/List';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import CloudIcon from '@material-ui/icons/Cloud';
import SettingsIcon from '@material-ui/icons/Settings';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom:65,
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }   ),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

function PersistentDrawerLeft( props ) {
    const { incident, insurer, user, role, onLogout } = props;
    const classes = useStyles();
    const theme = useTheme();
    let [ open, setOpen] = React.useState(false);

    const handleDrawerOpen = ( ) => {
        setOpen(true);
    };

    const handleDrawerClose = ( ) => {
        setOpen(false);
    };

        return (
            <div className={ classes.root }>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={ clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    }) }
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={ handleDrawerOpen }
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap>
                            Estudio Bujan
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{ paper: classes.drawerPaper }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={ handleDrawerClose }>
                          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List onClick={ handleDrawerClose }>
                        { user && <Link to="/user" style={{ textDecoration: 'none', color:"black" }}>
                            <ListItem button>
                                <PeopleIcon color="action"/>
                                <ListItemText primary="Usuarios" style={{marginLeft:"20px"}}/>
                            </ListItem>
                        </Link> }

                        { role && <Link to="/role" style={{ textDecoration: 'none', color:"black" }}>
                            <ListItem button >
                                <ListIcon color="action"/>
                                <ListItemText primary="Roles" style={{ marginLeft:"20px" }}/>
                            </ListItem>
                        </Link> }

                        { user && <Link to="/setting" style={{ textDecoration: 'none', color:"black" }}>
                            <ListItem button >
                                <SettingsIcon color="action"/>
                                <ListItemText primary="Ajustes" style={{marginLeft:"20px"}}/>
                            </ListItem>
                        </Link> }

                        { incident && <Link to="/incident" style={{ textDecoration: 'none', color:"black" }}>
                            <ListItem button >
                                <CloudIcon color="action"/>
                                <ListItemText primary="Siniestros" style={{ marginLeft:"20px" }}/>
                            </ListItem>
                        </Link> }

                        { insurer && <Link to="/insurer" style={{ textDecoration: 'none', color:"black" }}>
                            <ListItem button >
                                <SecurityIcon color="action"/>
                                <ListItemText primary="Aseguradoras" style={{ marginLeft:"20px" }}/>
                            </ListItem>
                        </Link> }

                        <ListItem button onClick={ onLogout }>
                            <ExitToAppIcon color="action"/>
                            <ListItemText primary="Salir" style={{ marginLeft:"20px" }}/>
                        </ListItem>

                    </List>
                </Drawer>
            </div>
        );
}

PersistentDrawerLeft.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout())
    };
};
export default connect(null,mapDispatchToProps)(withStyles(useStyles, { withTheme: true })(PersistentDrawerLeft));
