import React from "react";
import TextField from "@material-ui/core/TextField";

const MaterialInput = ({
  field: { /* value, */ ...fields },
  form: { touched, errors, ...rest },
  ...props
}) => {
  return (
    <TextField
      {...props}
      {...fields}
      size="small"
      margin="dense"
      error={Boolean(touched[fields.name] && errors[fields.name])}
      helperText={touched[fields.name] && errors[fields.name]}
    />
  );
};

export default MaterialInput;
