import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { instance } from '../../containers/getInstanceAxios.js';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: theme.spacing(25),
    },
  },
}));

export default function FormDialog( props ) {
  const classes = useStyles();
  const [campaign, setCampaign] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const handleSubmit = () => {
    instance.post( 'setting/', { campaign: campaign } ).then( res => {
      setOpenSuccess( true )
    }).catch( err => {
      setOpenError(true);
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
    setOpenError(false);
  };

  React.useEffect(() => {
    console.log( "useeffect" );
    instance.get( 'setting/' ).then( res => {
      setCampaign( res.data.campaign );
      setLoading( false );
    }).catch( err => {
      console.log( "err", err );
    });
  }, [] );

  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Paper elevation={3}>
          <Grid item xs={10} style={{marginLeft: "20px", marginTop: "20px"}}>
            <TextField
                autoFocus
                margin="dense"
                id="campaign"
                label="Campaña"
                type="name"
                required
                helperText="Número de 4 dígitos"
                disabled={loading}
                value={campaign}
                onChange={elem => setCampaign( elem.target.value )}
            />
          </Grid>
          <Button onClick={handleSubmit} color="primary" variant="contained" style={{marginTop: "40px", marginLeft: "40px"}}>
            Actualizar
          </Button>
        </Paper>

        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Actualizado!
          </Alert>
        </Snackbar>
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            Ocurrió un error!
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
}
