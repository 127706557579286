/* eslint-disable no-use-before-define */
import React, { Component }  from 'react';
import { Switch, FormControlLabel, FormControl, MenuItem, Divider, Button, Dialog, DialogTitle } from '../materialUi.js';
import { Formik, Field, Form } from 'formik';
import TextField from "../FormikTextField";
import * as Yup from "yup";
import { SimpleSnackbar } from '../SimpleSnackbar.js';
import Grid from '@material-ui/core/Grid';
import { instance } from '../getInstanceAxios.js';
import MyAutocomplete from '../../components/Autocomplete/Autocomplete.js';
import Spinner from '../../components/Spinner/Spinner';
import MySelect from './MySelect.js';
import UploadButton from '../../components/UploadButton/UploadButton.js';
import { handleDownloadFile } from '../../components/HandleDownloadFile/HandleDownloadFile.js';
import AddLocation from '../../components/AddLocation/AddLocation.js';
import TextFieldUI from 'material-ui/TextField';

class UpdateIncident extends Component {
    state={
        error: null,
        isLoaded: false,
        isUpdating: false,
        //estados para los input de autocomplete de UpdateModal
        name_location:"",
        name_department:"",
        name_province:"",

        data:[],
        insurer_amount_unit:["$", "USD", "qq"],
        franchise_type:["DEDUCIBLE", "NO DEDUCIBLE"],
        crop: ["SOJA","MAIZ","GIRASOL","TRIGO" ,"CEBADA","SOJA DE 2DA","MAIZ DE 2DA", "AVENA", "CENTENO", "PAPA", "ARROZ", "SORGO"],
        event_type:["GRANIZO","VIENTO","HELADA","PLANCHADO","INCENDIO"],
        previous_damage:["SI", "NO"],
        state:["ESPERANDO ACEPTACION", "ACEPTADO", "CONTACTADO", "TASADO", "RECHAZADO"],

        //Habilitar a medida que vaya cargando la provincia
        enabledDepartment:false,
        enabledLocation:false,

        //Options para los input autocomplete
        location:[],
        department:[],
        province:[],

        location_id:null,

        appraisersOptions:[],
        insurer_company:[],

        //Switch control
        addCrop:false,

    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = ( ) => {
        Promise.all([this.getUsersAppraiser(), this.getInsurer( ), this.getProvince( )]).then(values => {
            instance.get( '/incident/'+ this.props.update_id )
            .then( res => {
                Promise.all([ this.getDepartment( {id:res.data.province_id} ), this.getLocation( {id:res.data.department_id} ) ]).then(result => {
                    console.log("aprrasise", values[0]);
                    this.setState({
                        appraisersOptions: values[0],
                        insurer_company:values[1],
                        province:values[2],
                        department: result[0].isAxiosError ? [] : result[0],
                        location: result[1].isAxiosError ? [] : result[1],
                        name_location:this.getNameLocation( result[1], res.data.location_id),
                        name_province:this.getNameProvince( values[2], res.data.province_id ),
                        name_department:this.getNameDepartment( result[0], res.data.department_id ),
                        data:res.data,
                        isLoaded:true,
                        location_id: this.state.data.location_id,
                    });
                });
            });
        })
        .catch( err => {
            this.setState({ error:err });
        });
    }

    handleSubmit = (values,  { setSubmitting }) => {
        this.setState({ isUpdating: true })
        let payload = {};
        let crop = "";
        let item = "";
        let event_type = [];

        payload = {
            ...payload,
            "event_type": values.event_type.map(t => t.value)
        }

        if ( values.franchise_value)
            payload = {
                ...payload,
                "franchise_value": values.franchise_value
            };

        if (values.insurer_amount) {
            payload = {
                ...payload,
                "insurer_amount": values.insurer_amount,
            }
        }

        if ( values.insurer_amount_unit )
            payload = { ...payload, "insurer_amount_unit": values.insurer_amount_unit };

        if (!(this.state.crop.includes(values.crop))) {
            crop = "OTRO:"+values.crop;
        }else{
            crop = values.crop;
        }

        for (var i = 0; i < payload.event_type.length; i++) {
            if (!(this.state.event_type.includes(payload.event_type[i]))) {
                item = "OTRO:" + payload.event_type[i];
                event_type.push( item );
            }else{
                item = payload.event_type[i];
                event_type.push( item );
            }
        }

        payload = {
            ...payload,
            "insurer_id":values.insurer_company,
            "policy_number":values.policy_number,
            "incident_number":values.incident_number,
            "incident_date":values.incident_date,
            "office_entrance":values.office_entrance,
            "event_type": event_type,
            "countryside_name": values.countryside_name,
            "crop":crop,
            "lot_name": values.lot_name,
            "contact_name":values.contact_name,
            "insured_name":values.insured_name,
            "phone":values.phone,
            "franchise_type": values.franchise_type,
            "coinsurer_company":values.coinsurer_company,
            "previous_damage":values.previous_damage,
            "hectares_number":values.hectares_number,
            "record": values.record,
            "location_id": this.state.location_id,
            "state": values.state,
            "appraisers": values.appraisers.map(t => t.value),
            "observations": values.observations,
            "uploadFiles": values.files
        };

        setTimeout(() => {
            this.props.handleSubmitUpdate( payload );
            setSubmitting(false);
        }, 1000);
    };

    getFiles = () => instance.get( '/incident/' + this.props.update_id + '/file' );
    deleteFile = namefile => instance.delete( '/incident/' + this.props.update_id + '/file?name=' + namefile );
    downloadFile = namefile => instance.get( '/incident/' + this.props.update_id + '/file?name=' + namefile, { responseType: 'blob' } )
        .then( res => handleDownloadFile( res, namefile ) );

    getProvince = async ( ) => {
        try{
            let res = await instance.get('province');
            return res.data;
        } catch ( error ) {
            return error;
        }
    }

    getInsurer = async ( page=1 ) => {
        try{
            let res = await instance.get('insurer?page='+page);
            return res.data;
        } catch ( error ) {
            return error;
        }
    }

    getUsersAppraiser = async ( ) => {
        try{
            let res = await instance.get('user/appraiser');
            return res.data;
        } catch ( error ) {
            return error;
        }
    }

    getNameProvince = ( arr, id ) => {
        for ( var i = 0; i < arr.length; i++ ) {
            if ( arr[i].id === id ) {
                return arr[i];
            }
        }
    }

    getNameDepartment = ( arr, id ) => {
        for ( var i = 0; i < arr.length; i++ ) {
            if ( arr[i].id === id ) {
                return arr[i];
            }
        }
    }

    getNameLocation = ( arr, id ) => {
        for ( var i = 0; i < arr.length; i++ ) {
            if ( arr[i].id === id ) {
                return arr[i];
            }
        }
    }

    getDepartment = async ( value ) => {
        try{
            let res = await instance.get('province/'+ value.id + '/department' );
            return res.data;
        } catch ( error ) {
            return error;
        }
    }

    getLocation = async( value ) => {
        try{
            let res = await instance.get( 'department/'+ value.id + '/location');
            return res.data;
        } catch ( error ) {
            return error;
        }
    }

    getDepartmentToUpdate = async ( value ) => {
        if ( !value )
            return this.setState({ name_location: "", name_department: "", name_province: "", location_id: null });

        try{
            let res = await instance.get('province/'+ value.id + '/department' );
            this.setState({ department:res.data, name_province: value  });
        } catch ( error ) {
            return error;
        }
    }

    getLocationToUpdate = async( value ) => {
        if ( !value )
            return this.setState({ name_location: "", name_department: "", location_id: null });

        try{
            let res = await instance.get( 'department/'+ value.id + '/location');
            this.setState({ location:res.data, name_department: value });
        } catch ( error ) {
            return error;
        }
    }

    setLocationToUpdate = ( value ) => {
        if ( !value )
            value = { id: null, name: "" };
        this.setState({ location_id: value.id, name_location: value.name });
    }

    changeCrop = (event) => {
        this.setState({ cropValue: event.target.value });
    }

    handleChangeSwitchCrop = () => {
        this.setState({ addCrop:!this.state.addCrop })
    }

    initializeEventType = ( e ) => {
        let event_t = [];
        for (var i = 0; i < e.length; i++) {
            if (e[i].value.includes("OTRO:")) {
                event_t.push({ value: e[i].value.substring(5, e[i].value.length), label:e[i].value.substring(5, e[i].value.length) });
            }else {
                event_t.push(e[i]);
            }
        }
        return event_t;
    }

    initializeCrop = ( c ) => {
        if (c.includes("OTRO:")) {
            return c.substring(5, c.length);
        } else {
            return c;
        }
    }

    render () {
        console.log("state", this.state);
        let newEvent = this.state.event_type.map( et => ( { value: et, label: et } ) );
        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
        const { setFieldValue, handleClose, errorModal } = this.props;
        if (!this.state.isLoaded) {
            return <Dialog
                open={true}
                onClose={handleClose}
                fullScreen={true}
            >
                <Spinner />
            </Dialog>
        } else {
            return (
                <Dialog
                    open={true}
                    onClose={handleClose}
                    fullScreen={true}
                >
                    {errorModal && <SimpleSnackbar message={errorModal}/>}
                    <Formik
                        initialValues={{
                            id: this.props.update_id,
                            appraiser_accept: this.state.data.appraiser_accept,
                            appraiser_contact: this.state.data.appraiser_contact,
                            appraiser_record: this.state.data.appraiser_record,
                            appraiser_sent: this.state.data.appraiser_sent,
                            coinsurer_company: this.state.data.coinsurer_company,
                            contact_name: this.state.data.contact_name,
                            countryside_name: this.state.data.countryside_name,
                            crop: this.initializeCrop( this.state.data.crop ),
                            event_type: this.initializeEventType( this.state.data.event_type.split(',').map( et => ( { value: et, label: et } ) ) ),
                            franchise_type: this.state.data.franchise_type,
                            franchise_value: this.state.data.franchise_value,
                            hectares_number: this.state.data.hectares_number,
                            incident_date: this.state.data.incident_date,
                            incident_number: this.state.data.incident_number,
                            insured_name: this.state.data.insured_name,
                            insurer_amount: this.state.data.insurer_amount,
                            insurer_amount_unit: this.state.data.insurer_amount_unit,
                            insurer_id: this.state.data.insurer_id,
                            location_id: this.state.data.location_id,
                            internal_number: this.state.data.internal_number,
                            lot_name: this.state.data.lot_name,
                            office_entrance: this.state.data.office_entrance,
                            phone: this.state.data.phone,
                            policy_number: this.state.data.policy_number,
                            previous_damage: this.state.data.previous_damage,
                            state: this.state.data.state,
                            appraisers: this.state.data.appraisers && this.state.data.appraisers.map(a => ({value: a.id, label: a.name, state: a.state})),
                            department_id: this.state.data.department_id,
                            province_id: this.state.data.province_id,
                            observations: this.state.data.observations,
                            files: null,
                         }}
                        validationSchema={ Yup.object().shape({
                            coinsurer_company: Yup.string().max(30, 'Máximo 30 caracteres').nullable(),
                            contact_name: Yup.string().required('Selección requerida'),
                            countryside_name: Yup.string().max(30, 'Máximo 30 caracteres').nullable(),
                            crop: Yup.string().required('Selección requerida'),
                            event_type: Yup.array().nullable().required('Selección requerida')
                            .min(1, 'Selección requerida')
                            .of( Yup.object().shape({
                                label: Yup.string().required(),
                                value: Yup.string().required(),
                              })
                            ),
                            franchise_value: Yup.number().max(255, 'Máximo 255').nullable().typeError('Ingrese un numero'),
                            hectares_number: Yup.number().typeError('Selección requerida'),
                            incident_date: Yup.date().required("Debe ingresar una fecha"),
                            incident_number: Yup.string().max(30, 'Máximo 30 caracteres').nullable(),
                            insured_name: Yup.string().required('Selección requerida').max(50, 'Máximo 50 caracteres'),
                            insurer_amount:Yup.number().nullable().typeError('Ingrese un numero'),
                            lot_name: Yup.string().max(30, 'Máximo 30 caracteres').nullable(),
                            observations: Yup.string().max(1000, 'Máximo 1000 caracteres').nullable(),
                            office_entrance: Yup.date().required("Debe ingresar una fecha"),
                            phone: Yup.string().required('Selección requerida').matches(phoneRegExp, 'Número ingresado no valido').max(30, 'Máximo 30 caracteres'),
                            policy_number: Yup.string().max(30, 'Máximo 30 caracteres').nullable(),
                            appraisers: Yup.array().nullable().required('Selección requerida')
                                .min(1, 'Selección requerida')
                                .of( Yup.object().shape({
                                    label: Yup.string().required(),
                                    value: Yup.string().required(),
                                })
                            ),                        
                        })}
                        onSubmit={this.handleSubmit}
                        enableReinitialize={true}
                        render={(props) => ( <Form>
                        <DialogTitle style={{marginLeft:"3px"}} id="form-dialog-title">SINIESTRO</DialogTitle>
                        <Divider/>

                        <Grid container xs={9} style={{margin:"25px"}} direction={'row'}>
                            <Grid item xs={2}>
                                <FormControl>
                                    <Field
                                        disabled
                                        label="Nro interno"
                                        type="text"
                                        name="internal_number"
                                        onChange={setFieldValue}
                                        component={TextField}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl>
                                    <Field
                                        disabled={!this.props.canCreate}
                                        label="Número de poliza"
                                        type="text"
                                        name="policy_number"
                                        onChange={setFieldValue}
                                        component={TextField}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl>
                                    <Field
                                        disabled={!this.props.canCreate}
                                        label="Número de siniestro"
                                        type="text"
                                        name="incident_number"
                                        onChange={setFieldValue}
                                        component={TextField}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <Field
                                    disabled={!this.props.canCreate}
                                    label="Fecha del siniestro"
                                    type="date"
                                    name="incident_date"
                                    component={TextField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container xs={11} style={{ margin:"25px"}} direction={'row'}>
                            <Grid item xs={3}>
                                <Field
                                    disabled={!this.props.canCreate}
                                    label="Ingreso al estudio"
                                    type="date"
                                    name="office_entrance"
                                    component={TextField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            {this.props.canCreate &&
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        label="Agregar cultivo"
                                        style={{ width: 200, marginTop:"20px" }}
                                        control={
                                            <Switch
                                                onChange={() => this.handleChangeSwitchCrop()}
                                                value={ this.state.addCrop }
                                            />
                                        }
                                    />
                                </Grid>
                            }
                            <Grid item xs={3}>
                                { this.state.addCrop ?
                                    <FormControl>
                                        <Field
                                            disabled={!this.props.canCreate}
                                            type="text"
                                            label="Agregue el nuevo cultivo"
                                            name="crop"
                                            onChange={setFieldValue}
                                            component={TextField}
                                            style={{ width: 200 }}
                                        />
                                    </FormControl>
                                    :
                                    <FormControl>
                                        <Field
                                            disabled={!this.props.canCreate}
                                            style={{ width:"180px" }}
                                            name="crop"
                                            select
                                            label="Elija el cultivo"
                                            component={TextField}
                                            >
                                            <option value={props.values.crop} selected disabled hidden>{props.values.crop}</option>
                                            {this.state.crop.map( c => (
                                                <MenuItem value={c} key={c}>
                                                    {c}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                }
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl>
                                    <Field
                                        disabled={!this.props.canCreate}
                                        label="Nombre del campo"
                                        type="text"
                                        name="countryside_name"
                                        onChange={setFieldValue}
                                        component={TextField}
                                        style={{ width: 150 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControl>
                                    <Field
                                        disabled={!this.props.canCreate}
                                        label="Nombre de lote"
                                        type="text"
                                        name="lot_name"
                                        onChange={setFieldValue}
                                        component={TextField}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Grid container xs={10} style={{ margin:"25px"}} direction={'row'}>
                            <Grid item xs={2}>
                                <FormControl>
                                    <Field
                                        disabled={!this.props.canCreate}
                                        type="text"
                                        label="Nombre de contacto"
                                        name="contact_name"
                                        onChange={setFieldValue}
                                        component={TextField}
                                        style={{ width: 150 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl>
                                    <Field
                                        disabled={!this.props.canCreate}
                                        label="Nombre asegurado"
                                        type="text"
                                        name="insured_name"
                                        onChange={setFieldValue}
                                        component={TextField}
                                        style={{ width: 150 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl>
                                    <Field
                                        disabled={!this.props.canCreate}
                                        label="Telefono"
                                        type="text"
                                        name="phone"
                                        onChange={setFieldValue}
                                        component={TextField}
                                        style={{ width: 150 }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <MySelect
                                    disabled={!this.props.canCreate}
                                    newItems={newEvent}
                                    title="Tipo de eventos"
                                    name="event_type"
                                    value={props.values.event_type}
                                    onChange={props.setFieldValue}
                                    onBlur={props.setFieldTouched}
                                    error={props.errors.event_type}
                                    touched={props.touched.event_type}
                                />
                            </Grid>
                        </Grid>
                            <Divider/>
                            <Grid container xs={11} style={{margin:"25px"}}>
                                <Grid item xs={2}>
                                    <FormControl>
                                        <Field
                                            disabled={!this.props.canCreate}
                                            label="Suma asegurada(Ha)"
                                            type="text"
                                            name="insurer_amount"
                                            onChange={setFieldValue}
                                            component={TextField}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl>
                                        <Field
                                            disabled={!this.props.canCreate}
                                            label="Unidad"
                                            name="insurer_amount_unit"
                                            select
                                            onChange={setFieldValue}
                                            component={ TextField }
                                            style={{ width: 70 }}
                                            >
                                            {this.state.insurer_amount_unit.map( item => (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl>
                                        <Field
                                            disabled={!this.props.canCreate}
                                            label="Tipo de franquicia"
                                            name="franchise_type"
                                            select
                                            onChange={setFieldValue}
                                            component={ TextField }
                                            style={{ width: 150 }}
                                            >
                                            {this.state.franchise_type.map( item => (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl>
                                        <Field
                                            disabled={!this.props.canCreate}
                                            label="Valor franquicia (%)"
                                            type="text"
                                            name="franchise_value"
                                            onChange={setFieldValue}
                                            component={TextField}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl>
                                        <Field
                                            disabled={!this.props.canCreate}
                                            label="Co-aseguradora"
                                            type="text"
                                            name="coinsurer_company"
                                            onChange={setFieldValue}
                                            component={TextField}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Divider/>
                            <Grid container xs={10} style={{margin:"25px"}}>
                                <Grid item xs={2}>
                                    <FormControl>
                                        <Field
                                            disabled={!this.props.canCreate}
                                            label="Daño anterior"
                                            name="previous_damage"
                                            select
                                            onChange={setFieldValue}
                                            component={ TextField }
                                            style={{ width: 100 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            >
                                            {this.state.previous_damage.map( item => (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl>
                                        <Field
                                            disabled={!this.props.canCreate}
                                            label="Cantidad de hectareas"
                                            type="text"
                                            name="hectares_number"
                                            onChange={setFieldValue}
                                            component={TextField}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} style={{marginLeft:"20px"}}>
                                    <MySelect
                                        disabled={!this.props.canCreate}
                                        title="Tasador"
                                        name="appraisers"
                                        newItems={this.state.appraisersOptions.map( a => ( { value: a.id, label: a.name } ) )}
                                        value={props.values.appraisers}
                                        onChange={props.setFieldValue}
                                        onBlur={props.setFieldTouched}
                                        error={props.errors.appraisers}
                                        touched={props.touched.appraisers}
                                        placeholder={"Puede ingresar multiples tasadores"}
                                    />
                                </Grid>
                            </Grid>
                            <Divider/>
                            <h5 style={{margin:"25px"}}>TASADORES</h5>
                            { props.values.appraisers.map(appraiser => <Grid container xs={11} style={{margin:"25px"}}>
                                <Grid item xs={2} style={{marginTop: "15px"}}>
                                    <h5>{`(${appraiser.value}) - ${appraiser.label}:`}</h5>
                                </Grid>
                                <Grid item xs={2}>
                                    <Field
                                        disabled
                                        label="Envio al tasador"
                                        type="date"
                                        name="appraiser_sent"
                                        component={TextField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{width:"200px"}}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Field
                                        disabled
                                        label="Tasador acepta al siniestro"
                                        type="date"
                                        name="appraiser_accept"
                                        component={TextField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{width:"250px"}}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        disabled
                                        label="Tasador se contacta con el asegurado"
                                        type="date"
                                        name="appraiser_contact"
                                        component={TextField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{width:"300px"}}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Field
                                        disabled
                                        label="Tasador envia el acta"
                                        type="date"
                                        name="appraiser_record"
                                        component={TextField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{width:"200px"}}
                                    />
                                </Grid>
                            </Grid>)}
                            <Grid container xs={11} style={{margin:"25px"}}>
                                <Grid item xs={2}>
                                    <MyAutocomplete
                                        value={this.state.name_province}
                                        label="Provincia"
                                        disabled={ !this.props.canCreate && this.props.canUpload}
                                        options={this.state.province}
                                        onChange={this.getDepartmentToUpdate}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <MyAutocomplete
                                        value={this.state.name_department}
                                        label="Departamento"
                                        options={this.state.department}
                                        disabled={ !this.props.canCreate && this.props.canUpload}
                                        onChange={this.getLocationToUpdate}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <MyAutocomplete
                                        value={this.state.name_location}
                                        label="Localidad"
                                        disabled={ !this.props.canCreate && this.props.canUpload}
                                        options={this.state.location}
                                        onChange={this.setLocationToUpdate}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{marginTop:"25px"}}>
                                    <AddLocation provinces={this.state.province} />
                                </Grid>
                            </Grid>
                            <Divider/>
                            <Grid container xs={8} style={{margin:"25px"}}>
                                <Field
                                    type="content"
                                    name="observations"
                                    label="Observaciones"
                                    component={TextField}
                                    margin="normal"
                                    rowsMax={3}
                                    rows={2}
                                    style={{ width: '100%' }}
                                    multiline
                                />
                            </Grid>
                            <Divider/>
                            <Grid container xs={11} style={{margin:"25px"}}>
                                <UploadButton
                                  name="files"
                                  handleAdd={props.setFieldValue}
                                  handleDelete={this.deleteFile}
                                  handleDownload={this.downloadFile}
                                  getFiles={this.getFiles}
                                  listFiles={true}
                                />
                            </Grid>
                                <Grid container xs={11} style={{marginLeft: "30px"}}>
                                    <Grid container xs={6} style={{marginBottom: "30px"}}>
                                        { this.state.isUpdating ? <Button size="large" color="primary" variant="contained" disabled style={{marginRight:"25px"}}>
                                            Actualizando ...
                                        </Button> : <Button size="large" color="primary" variant="contained" type="submit" style={{marginRight:"25px"}}>
                                            Confirmar
                                        </Button>
                                        }
                                        <Button size="large" color="default" variant="contained" onClick={handleClose}>
                                            Cancelar
                                        </Button>
                                    </Grid>
                                </Grid>

                        </Form>
                    )}
                    />

                </Dialog>
            );
        }
    }
}


export default UpdateIncident;
