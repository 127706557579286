import React from 'react';
import { Dialog,DialogContent,DialogTitle } from '../materialUi.js';
import { withFormik } from 'formik';
import TextField from "../FormikTextField";
import { Field } from 'formik';
import * as Yup from "yup";
import Button from '@material-ui/core/Button';
import Switches from './Switches';
import { SimpleSnackbar } from '../SimpleSnackbar.js';

var divStyle = {
  margin: "20px"
};

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        email: Yup.string().email().required('Email requerido'),
        password: Yup.string().required('Password requerido'),
        name: Yup.string().required('Nombre requerido'),
    }),

    mapPropsToValues: props => ({
        handleSubmitAdd: props.handleSubmitAdd,
        roles: [],
        email: '',
        name: '',
        password: ''
    }),

    handleSubmit: (values, { setSubmitting }) => {
        const payload = {
            ...values,
        };
        setTimeout(() => {
            values.handleSubmitAdd( payload );
            setSubmitting(false);
        }, 1000);
    },
    displayName: 'MyForm',
});

const MyForm = props => {
    const {
        values,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        handleClose,
        errorModal,
        rolesOptions
    } = props;
    const rolCargador = rolesOptions.find( role => role.name === 'CARGAR' );
    return (
        <Dialog
            maxWidth="md"
            open={true}
            onClose={handleClose}
            fullScreen={true}
        >
            {errorModal && <SimpleSnackbar message={errorModal}/>}
            <form onSubmit={handleSubmit}>
                <DialogTitle style={{marginLeft:"7px"}} id="form-dialog-title">Agregar Usuario</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div width="33%" className="form-group" style={divStyle}>
                            <Field
                                style = {{width: "515px"}}
                                type="email"
                                label="Email"
                                name="email"
                                margin="normal"
                                onChange={setFieldValue}
                                variant="outlined"
                                component={TextField}
                            />
                        </div>
                        <div width="33%" className="form-group" style={divStyle}>
                            <Field
                                style = {{width: "515px"}}
                                type="name"
                                label="Nombre"
                                name="name"
                                margin="normal"
                                onChange={setFieldValue}
                                variant="outlined"
                                component={TextField}
                            />
                        </div>
                        <div width="33%" className="form-group" style={divStyle}>
                            <Field
                                style = {{width: "515px"}}
                                type="password"
                                label="Contraseña"
                                name="password"
                                margin="normal"
                                onChange={setFieldValue}
                                variant="outlined"
                                component={TextField}
                            />
                        </div>
                    </div>
                    <div className="col">
                        <h5 style={{marginLeft:"0px"}}>Asignar roles</h5>
                        <Switches
                            roles={rolesOptions}
                            value={values.roles}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            error={errors.roles}
                            cargadorId={rolCargador ? rolCargador.id : -1}
                        />
                    </div>
                    <div className="row" style={{marginLeft:"-5px"}} >
                        <Button variant="contained" type="submit" style={{margin:"5px"}}>
                            Confirmar
                        </Button>
                        <Button variant="contained" onClick={handleClose} style={{margin:"5px"}}>
                            Cancelar
                        </Button>
                    </div>
                    <div style={{marginBottom:"200px"}}></div>

                </DialogContent>
            </form>
        </Dialog>);
}


const AddModal = formikEnhancer(MyForm);
export default AddModal;
