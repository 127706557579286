import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MyAutocomplete from '../../components/Autocomplete/Autocomplete.js';
import { instance } from '../../containers/getInstanceAxios.js';
import Icon from '@material-ui/core/Icon';

export default function FormDialog( props ) {
  const [open, setOpen] = React.useState(false);
  const [departments, setDepartments] = React.useState([]);
  const [departmentId, setDepartmentId] = React.useState(0);
  const [location, setLocation] = React.useState("");

  const { provinces } = props;

  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };
  const handleSubmit = () => {
    instance.post( 'location/', { department_id: departmentId, name: location } );
    setOpen( false );
  };

  const getDepartments = province => {
    if ( !province )
      return;

    instance.get( 'province/'+ province.id + '/department' ).then( res => {
      setDepartments( res.data );
    }).catch( err => {
      console.log( "err", err );
    });
  }

  return (
    <div>
      <Button variant="outlined" size="small" color="primary" onClick={handleClickOpen} startIcon={<Icon color="primary">add_circle</Icon>}>
        Crear Localidad
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"lg"}>
        <DialogTitle id="form-dialog-title">Agregar Localidad</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecciona Provincia, Departamento y luego ingresa el nombre de la Localidad.
          </DialogContentText>
          <Grid container item xs={12}>
              <Grid item xs={4}>
                  <MyAutocomplete
                      label="Provincia"
                      disabled={false}
                      options={provinces}
                      onChange={getDepartments}
                  />
              </Grid>
              <Grid item xs={4}>
                  <MyAutocomplete
                      label="Departamento"
                      disabled={false}
                      options={departments}
                      onChange={elem => elem && setDepartmentId( elem.id )}
                  />
              </Grid>
              <Grid item xs={4} style={{marginTop: "10px"}}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Localidad"
                    type="name"
                    onChange={elem => setLocation( elem.target.value )}
                />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Confirmar
          </Button>
          <Button onClick={handleClose} color="default" variant="contained">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
