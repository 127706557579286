import React, { Fragment }  from 'react';
import { divStyle } from './tableStyles';
import { Typography, IconButton, Tooltip, Fab, AddIcon } from '../../containers/materialUi.js';
import FilterListIcon from '@material-ui/icons/FilterList';
export const addItem = ( classes, onAddItem ) => {
    return(
        <div style={divStyle}>
            <Fab style={{ backgroundColor: '#f50057'}}
                size="small"
                color='primary'
                aria-label="Agregar"
                title="Agregar"
                className={classes.fab}
                onClick={ onAddItem }>
                <AddIcon />
            </Fab>
        </div>
    );
};

export const showTitle = ( classes, title ) => {
    return(
        <div style={{ margin:"5px"}} >
            <div className={ classes.title }>
                <Typography variant="h5" id="tableTitle">
                    { title }
                </Typography>
            </div>
        </div>
    );
};

export const editItem = ( onUpdate, checked ) => {
    return(
        <div style={{ margin:"5px"}} >
            <Tooltip title="Ver" onClick={ () => onUpdate( checked )}>
                <IconButton aria-label="Edit" background-color="#f50057">
                    <svg width="24px" height="24px" viewBox="0 0 24 24"><path fill="#000000" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" /></svg>
                </IconButton>
            </Tooltip>
        </div>
    );
};

export const numItemSelected = ( classes, numSelected ) =>{
    return(
        <div style={{ margin:"5px"}} >
            <div style={{ marginRight:"10px"}} >
                <div className={classes.title}>
                    {numSelected} seleccionado/s
                </div>
            </div>
        </div>
    );
};

export const deleteItem = ( onDelete, checked ) =>{
    return(
        <div style={{ margin:"5px"}} >
            <Tooltip title="Eliminar" onClick={ () => window.confirm( "Queres eliminar estos elementos?" ) ? onDelete( checked ) : false }>
                <IconButton aria-label="Eliminar">
                    <svg width="24px" height="24px" viewBox="0 0 24 24"><path fill="#000000" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>
                </IconButton>
            </Tooltip>
        </div>
    );
};

export const filterItem = ( onFilter ) => {
    return(
        <div style={{ margin:"5px"}} >
            <Fragment>
                <Tooltip title="Filtrar" style={{ backgroundColor: '#605a73'}} onClick={ () => onFilter() } >
                     <IconButton aria-label="Filtrar">
                         <FilterListIcon style={{color:'#ffff'}}/>
                     </IconButton>
                 </Tooltip>
            </Fragment>
        </div>
    );
};
