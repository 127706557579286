import { addItem, showTitle, editItem, numItemSelected, filterItem, deleteItem } from './funcionalities';
import React from 'react';
import { withStyles, Toolbar } from '../../containers/materialUi.js';
import classNames from 'classnames';

//Header of the table, is all funcionalities from user
const TableToolbarGeneric = props => {
    let { numSelected, classes, onAddItem, onFilter, checked, onUpdate, onDelete, title, selectAll, canCreate, canDelete, canRead } = props;
    let classStyle = classNames( classes.root, { [classes.highlight]: numSelected > 0 } );
    if ( title==="Siniestros" ) {
        return (
            <Toolbar
                style={{ minHeight: '80px', backgroundColor: '#fcfcfc'}}
                className={classStyle}>
                { checked.length === 0 && canCreate && addItem( classes, onAddItem ) }
                { checked.length === 0 && showTitle( classes, title) }
                { checked.length === 0 && filterItem( onFilter ) }
                { checked.length === 1 && canRead && editItem( onUpdate, checked ) }
                { checked.length === 1 && numItemSelected( classes, numSelected ) }
                { checked.length >= 2 && !selectAll && numItemSelected( classes, numSelected ) }
                { selectAll && numItemSelected( classes, numSelected ) }
            </Toolbar>
        );
    } else {
        return(
            <Toolbar>
                { checked.length === 0 && canCreate && addItem( classes, onAddItem ) }
                { checked.length === 0 && showTitle( classes, title) }
                { checked.length === 1 && numItemSelected( classes, numSelected ) }
                { checked.length === 1 && canCreate && editItem( onUpdate, checked ) }
                { (title==="Usuarios" || title==="Roles") && checked.length === 1 && canDelete && deleteItem( onDelete, checked ) }
                { checked.length >= 2 && !selectAll && numItemSelected( classes, numSelected ) }
                { (title==="Usuarios" || title==="Roles") && checked.length >= 2 && !selectAll && canDelete && deleteItem( onDelete, checked ) }
                { selectAll && numItemSelected( classes, numSelected ) }

            </Toolbar>
        );
    }
};

export default withStyles()(TableToolbarGeneric);
