import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, Checkbox } from '../../containers/materialUi.js';

class TableHeadGeneric extends React.Component {

    render() {
        const { onSelectAllClick, numSelected, rowCount, rows } = this.props;

        return (
                <TableHead>
                    <TableRow style={{ backgroundColor: '#FFFFFF' }}>
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={ numSelected > 0 && numSelected < rowCount }
                                checked={ numSelected === rowCount }
                                onChange={ onSelectAllClick }
                            />
                        </TableCell>
                            {rows.map(row => (
                                    <TableCell
                                        key={ row.id }
                                        align={ row.numeric ? 'left' : 'left' }
                                        padding={ row.disablePadding ? 'none' : 'default' }
                                    >
                                    { row.label }
                                </TableCell>
                            ),this,
                            )}
                    </TableRow>
                </TableHead>
        );
    }
}

TableHeadGeneric.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
    rows: PropTypes.array.isRequired,
};

export default TableHeadGeneric;
