import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';


export const SimpleSnackbar = props => {
    const { message } = props;
    const [open, setOpen] = React.useState(true);

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    return (
        <div>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={ open }
                autoHideDuration={ 10000 }
                onClose={ handleClose }
                ContentProps={{ 'aria-describedby': 'message-id' }}
                message={<span id="message-id">{message}</span>}
                action={[
                    <Button key="undo" color="secondary" size="small" onClick={ handleClose }>
                        Cerrar
                    </Button>,
                ]}
            />
        </div>
    );
}
