import React, { Component } from 'react';
import Table from "../../components/Table/Table";
import { instance } from '../getInstanceAxios.js';
import Spinner from '../../components/Spinner/Spinner'
import AddModal  from './AddModal';
import UpdateModal  from './UpdateModal';
import FilterModal  from './FilterModal';
import AppraisalModal  from './AppraisalModal';
import { handleAllCheckBoxGeneric, handleCheckBoxGeneric, handleChangePageGeneric } from '../TableFunctions.js';
import { file2formdata } from '../../components/UploadButton/File2FormData';

class Incident extends Component {

    state = {
        data: [],
        dataToUpdate:[],
        error: null,
        isLoaded: false,
        openAdd: false,
        openUpdate: false,
        openFilter:false,
        openAppraisal:false,
        errorModal: null,
        pagination: {},
        selected: [],
        selectAll: false,
        viewTable:false,

        franchise_type:["DEDUCIBLE", "NO DEDUCIBLE"],
        insurer_amount_unit:["$", "USD", "qq"],
        crop: ["SOJA","MAIZ","GIRASOL","TRIGO" ,"CEBADA","SOJA DE 2DA","MAIZ DE 2DA", "AVENA", "CENTENO", "PAPA", "ARROZ", "SORGO"],
        event_type:["GRANIZO","VIENTO","HELADA","PLANCHADO","INCENDIO"],
        previous_damage:["SI", "NO"],
        state:["ESPERANDO ACEPTACION", "ACEPTADO", "CONTACTADO", "TASADO", "RECHAZADO"],
        insurer_company:[],
        appraiser:[],

        //Options para los input autocomplete
        location:[],
        department:[],
        province:[],

        //Habilitar a medida que vaya cargando la provincia
        enabledDepartment:false,
        enabledLocation:false,

        //estados para guardar lel id de la localidad
        location_id:null,

        //le paso al update el id del elemento
        update_id:null,

        //valor de cultivo para el add
        cropValue:"",
        eventValue:"",

        //valor del switch
        addCrop:false,

        //nombre de los archivos correspondientes al siniestro con estado TASADO
        name_files: [],
    }

    FILTER = '';

    componentDidMount() {
        this.getIncident();
    }

    getIncident = ( page=1, filter="" ) => {
        let url = 'incident?page='+page;
        if (filter) {
            url += '&'+filter;
            this.FILTER = '&'+filter;
        } else if (this.FILTER) {
            url += this.FILTER;
        }
        instance.get(url)
        .then( res => {
            const { total, current_page, per_page, data } = res.data;
            var genericData = data.map( item => {
                return {
                    first: item.id,
                    second: item.internal_number,
                    third: item.office_entrance.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1'),
                    fourth: item.insured_name,
                    fiveth: item.appraisers ? item.appraisers.map(e => e.name).toString(): "nada?",
                    sixth: item.appraiser_sent.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1'),
                    seventh: item.state,
                };
            });
            this.setState({
                data:genericData,
                pagination: {
                    total,
                    currentPage: current_page,
                    rowsPerPage: per_page
                },
                isLoaded:true,
                selected:[],
                selectAll:false,
                openFilter:false,
                viewTable:true,
            });
        })
        .catch( err => {
            this.setState({ error:err, isLoaded:true });
        });
    }

    /**
    Metodo encargado de activar el modal de Agregar Siniestro
    **/
    onAdd = () => {
        this.FILTER = '';
        const incidentReady = [
            this.getUsersAppraiser(),
            this.getInsurer(),
            this.getProvince(),
        ]
        Promise.all(incidentReady)
            .then( this.setState({ openAdd: true, errorModal:null }))
    }

    /**
    Metodo a cargo de crear el siniestro
    **/
    handleSubmitAdd = ( e ) => {
        let crop = "";
        let event_type = [];
        let item = "";

        if (!(this.state.crop.includes(e.crop))) {
            crop = "OTRO:"+e.crop;
        }else{
            crop = e.crop;
        }



        for (var i = 0; i < e.event_type.length; i++) {
            if (!(this.state.event_type.includes(e.event_type[i]))) {
                item = "OTRO:" + e.event_type[i];
                event_type.push( item );
            }else{
                item = e.event_type[i];
                event_type.push( item );
            }
        }

        const { uploadFiles, ...payload } = e;

        let formdata = {
            ...payload,
            "event_type": event_type,
            "crop":crop,
        };

        if (this.state.location_id) {
            formdata = {
                ...formdata,
                "location_id": this.state.location_id,
            }
        }

        instance.post( 'incident/', formdata )
        .then( res => {
            instance.post( 'incident/' + res.data.id + '/file', file2formdata( uploadFiles ), { headers: { "Content-Type": "multipart/form-data" } })
            .then( resSecond => {
                this.setState({
                    errorModal:"Creado",
                    openAdd:false,
                    selected:[],
                    selectAll:false,
                    enabledDepartment:false,
                    enabledLocation:false,
                    location_id:null,
                    fileUp:[],
                    cropValue:"",
                });
            })
            .catch( err => {
                this.setState({ error:err, openUpdate:false });
            });
            this.getIncident( );
        })
        .catch( err => {
            this.setState({ errorModal:err });
        });
    }

    onUpdate = ( id ) => {
        this.setState({
            cleanSelection: false,
            openUpdate: true,
            errorModal:null,
            update_id:id,
        });
    }

    /**
    Metodo a cargo de actualizar el rol con sus permisos asociados
    **/
    handleSubmitUpdate = async payload => {
        console.log('handleSubmitUpdate');
        const { uploadFiles, ...formdata } = payload;
        const canCreate = this.props.roles.includes('incident.create');

        try {
            if (canCreate)
                await instance.put( 'incident/'+ this.state.selected, formdata );
            if (uploadFiles)
                await instance.post( 'incident/' + this.state.selected + '/file', file2formdata( uploadFiles ), { headers: { "Content-Type": "multipart/form-data" } });
            if (!canCreate)
                await instance.put( 'incident/'+ this.state.selected, {observations: formdata.observations} );

            this.setState({
                selected:[],
                selectAll:false,
                openUpdate:false,
                cleanSelection: true,
                errorModal:"Actualizado",
                location_id:null
            });

            this.getIncident();
        } catch(err) {
            alert(err.response.data.message || 'Ocurrió un error');
        }
    }

    /**
        Metodo encargado de abrir el filter
     **/
    onFilter = async () => {
        try {
            await this.getUsersAppraiser();
            await this.getInsurer();
            this.FILTER = '';
            this.setState({ openFilter: true, viewTable:false });
        } catch ( error ) {
            process.env.NODE_ENV !== 'production' && console.log( error );
        }
    }

    /**
        Metodo encargado de abrir la tasación
     **/
    onAppraisal = ( id ) => {
        instance.get( 'incident/' + id + '/file?dir=result')
        .then( res => {
            this.setState({ update_id:id, name_files: res.data, openAppraisal: true });
        })
        .catch( err => {
            return err;
        });
    }

    onResetState = ( msg ) => {
        this.setState({
            selected:[],
            selectAll:false,
            openUpdate:false,
            openAppraisal:false,
            cleanSelection: true,
            errorModal:msg,
            location_id:null,
            isLoaded:false,
        });
        this.getIncident();
    }

    /**
    Metodo a cargo de cerrar el modal
    **/
    handleClose = () => {
        this.setState({
            errorModal:null,
            openUpdate: false,
            openFilter:false,
            openAppraisal:false,
            openAdd: false,
            name:null,
            selected:[],
            selectAll:false,
            viewTable:true
        });
    }


    handleAllCheckBox = event => {
        let result = handleAllCheckBoxGeneric( event, this.state.data );
        this.setState({ selectAll:result.selectAll, selected:result.selected })
    };

    handleCheckBox = ( event, id) => {
        let result = handleCheckBoxGeneric( event, id, this.state.selected );
        this.setState({ selectAll: false, selected: result.selected });
    };

    handleChangePage = (event, page) => {
        handleChangePageGeneric(event, page, this.getIncident )
    };

    isSelected = id => this.state.selected.indexOf( id ) !== -1;


    /** Funciones de otras entidades necesarias para siniestros **/

    getInsurer = () => {
        instance.get('insurer?list_all=true')
        .then( res => {
            this.setState({insurer_company:res.data});
        })
        .catch( err => {
            return err;
        });
    }

    getUsersAppraiser = ( ) => {
        instance.get('user/appraiser')
        .then( res => {
            this.setState({appraiser:res.data});
        })
        .catch( err => {
            return err;
        });
    }

    getProvince = ( ) => {
        instance.get('province')
        .then( res => {
            this.setState({province:res.data});
        })
        .catch( err => {
            return err;
        });
    }

    getDepartment = ( value ) => {
        if ( !value )
            return;
        instance.get('province/'+ value.id + '/department' )
            .then( res => {
                this.setState({ enabledDepartment:true, department:res.data, isLoaded:true });
            })
            .catch( err => {
                this.setState({ error:err, isLoaded:true });
            });
    }

    getLocation = ( value ) => {
        if ( !value )
            return;
        instance.get( 'department/'+ value.id + '/location')
            .then( res => {
                this.setState({ enabledLocation:true, location:res.data, isLoaded:true });
            })
            .catch( err => {
                this.setState({ error:err, isLoaded:true });
            });
    }

    setLocationToUpdate = ( value ) => {
        if ( value )
            this.setState({ location_id: value.id });
    }

    changeCrop = (event) => {
        this.setState({ cropValue: event.target.value });
    }

    handleChangeSwitchCrop = () => {
        this.setState({ addCrop:!this.state.addCrop })
    }

    handleChangeSwitchEvent = () => {
        this.setState({ eventValue:"", addEvent:!this.state.addEvent })
    }

    render () {
        const title = "Siniestros";
        const { error, data, isLoaded, openAdd, openUpdate, errorModal, pagination, cleanSelection } = this.state;
        const roles = this.props.roles;
        const rows = [{ id: 'Nro interno', numeric: true, disablePadding: true, label: 'Numero interno' },
                      { id: 'Ingreso al estudio', numeric: true, disablePadding: true, label: 'Ingreso al estudio' },
                      { id: 'Asegurado', numeric: true, disablePadding: true, label: 'Asegurado' },
                      { id: 'Tasadores', numeric: true, disablePadding: true, label: 'Tasadores' },
                      { id: 'Envio a los tasadores', numeric: true, disablePadding: true, label: 'Envio a los tasadores' },
                      { id: 'Estado', numeric: true, disablePadding: true, label: 'Estado' },
                      { id: '', numeric: true, disablePadding: true, label: '' },
                     ];

        if (!isLoaded) {
            return <div> <Spinner /></div>
        } else if(error){
            return <div><h1>{error.message}</h1></div>
        } else {
            return <div>
                <Table
                    viewTable= {this.state.viewTable}
                    title={title}
                    rows={rows}
                    data={data}
                    pagination={pagination}
                    errorModal={errorModal}
                    cleanSelection={cleanSelection}
                    onChangePage={this.getIncident}
                    onDelete= { this.onDelete }
                    onAddItem= { this.onAdd }
                    onUpdate={ this.onUpdate }
                    canCreate={roles.includes('incident.create')}
                    canRead={roles.includes('incident.read')}
                    handleAllCheckBox={this.handleAllCheckBox}
                    handleCheckBox={this.handleCheckBox}
                    handleChangePage={this.handleChangePage}
                    isSelected={this.isSelected}
                    selected={this.state.selected}
                    selectAll={this.state.selectAll}
                    onFilter={this.onFilter}
                    onAppraisal={this.onAppraisal}

                />
                {openAdd &&
                    <AddModal
                        handleChange={this.handleChange}
                        handleClose={this.handleClose}
                        handleSubmitAdd={this.handleSubmitAdd}
                        errorModal={errorModal}
                        insurer_company={this.state.insurer_company}
                        crop={this.state.crop}
                        franchise_value={this.state.franchise_value}
                        appraiser={this.state.appraiser}
                        state={this.state.state}
                        previous_damage={this.state.previous_damage}
                        location={this.state.location}
                        province={this.state.province}
                        department={this.state.department}
                        event_type={this.state.event_type}
                        franchise_type={this.state.franchise_type}
                        insurer_amount_unit={this.state.insurer_amount_unit}
                        enabledDepartment={this.state.enabledDepartment}
                        getDepartment={this.getDepartment}
                        getLocation={this.getLocation}
                        location_id={this.state.location_id}
                        setLocationToUpdate={this.setLocationToUpdate}
                        cropValue={this.state.cropValue}
                        eventValue={this.state.eventValue}
                        addCrop={this.state.addCrop}
                        addEvent={this.state.addEvent}
                        changeCrop={this.changeCrop}
                        handleChangeSwitchEvent={this.handleChangeSwitchEvent}
                        handleChangeSwitchCrop={this.handleChangeSwitchCrop}
                    />
                }
                {openUpdate &&
                    <UpdateModal
                        errorModal={errorModal}
                        handleClose={this.handleClose}
                        handleChange={this.handleChange}
                        handleSubmitUpdate={this.handleSubmitUpdate}
                        canRead={roles.includes('incident.read')}
                        canUpload={roles.includes('incident.upload')}
                        canCreate={roles.includes('incident.create')}
                        getUsersAppraiser={this.getUsersAppraiser}
                        getInsurer={this.getInsurer}
                        insurer_company={this.state.insurer_company}
                        getProvince={this.getProvince}
                        province={this.state.province}
                        getDepartment={this.getDepartment}
                        getLocation={this.getLocation}
                        department={this.state.department}
                        location={this.state.location}
                        update_id={this.state.update_id}
                        enabledDepartment={this.state.enabledDepartment}
                        enabledLocation={this.state.enabledLocation}

                    />
                }
                {this.state.openFilter &&
                    <FilterModal
                        handleClose={this.handleClose}
                        appraiser={this.state.appraiser}
                        states={this.state.state}
                        insurers={this.state.insurer_company}
                        crops={[...this.state.crop, "OTRO"]}
                        getIncident={this.getIncident}
                    />
                }
                {this.state.openAppraisal &&
                    <AppraisalModal
                        handleClose={this.handleClose}
                        initFiles={this.state.name_files}
                        isSelectedAppraisal={this.isSelectedAppraisal}
                        handleCheckBoxAppraisal={this.handleCheckBoxAppraisal}
                        update_id={this.state.update_id}
                        onResetState={this.onResetState}
                        canSendMail={roles.includes('incident.create')}
                    />
                }

            </div>
        }
    }
}

export default Incident;
