import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';

export default function MyAutocomplete(props) {
    return (
        <Autocomplete
            disabled={props.disabled}
            style={{marginRight:30}}
            value={props.value}
            options= {props.options}
            getOptionLabel= {option => option.name}
            autoSelect
            onChange={(event, values) => props.onChange(values)}
            renderInput={params => <TextField {...params} label={props.label} margin="normal" />}
        />
    );
}
